class DomUtil {
  scrollToHash = (hash, { topOffset = 0 } = {}) => {
    const id = hash.substr(1)
    const element = document.getElementById(id)
    if (element) {
      this.reportScroll(id)
      window.scroll(
        0,
        this.getPosition(document.getElementById(id)).top + topOffset
      )
    } else {
      this.reportScrollNotFound(id)
    }
  }

  scrollTop = () => window.scrollTo(0, 0)

  // Finds y value of given object
  getPosition = element => {
    const position = { top: 0, left: 0 }
    if (element.offsetParent) {
      // eslint-disable-next-line no-cond-assign
      do {
        position.top += element.offsetTop
        position.left += element.offsetLeft
        // eslint-disable-next-line no-param-reassign
      } while ((element = element.offsetParent))
      return position
    }
  }

  reportScroll = id => global.console.debug(`[DOM] Scrolling to ${id}`)

  reportScrollNotFound = id =>
    global.console.error(`[DOM] Scrolling to ${id} failed -> Element not found`)
}

export default DomUtil
