import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Divider from '@material-ui/core/Divider'
import Helmet from 'react-helmet'

import Filters from '../components/Filters'
import DishTypes from '../components/DishTypes'
import RestaurantList from '../components/RestaurantList'
import RestaurantListEndOfResultsModal from '../components/RestaurantListEndOfResultsModal'

class RestaurantListPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Helmet>
          <title>Plate - תפריטים של מסעדות בתל אביב</title>
          <meta name="description" content="תפריטים של מסעדות בתל אביב" />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://plate.io",
                "logo": "https://plate.io/images/512.png"
              }
            `}
          </script>
        </Helmet>
        <Filters />
        <Divider />
        <DishTypes />
        <Divider />
        <RestaurantList />
        <RestaurantListEndOfResultsModal />
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.headerWithSearchHeight,
  },
})

export default withStyles(styles)(RestaurantListPage)
