import categories from '../data/categoriesData.json'
import { DISH_ATTRIBUTE } from '../constants/constants'

export const isDev = process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'
export const isSwForcedInDev = process.env.REACT_APP_SW === 'true'

export const imgPlaceholder = (x, y) => {
  if (y) {
    return `https://via.placeholder.com/${x}x${y}`
  }
  return `https://via.placeholder.com/${x}`
}

export const decodeURISafely = uri => {
  try {
    return decodeURI(uri)
  } catch (err) {
    global.console.error('decodeURISafely failed for', uri)
    return ''
  }
}

// todo try this across app, publish as npm if works
export const tryCatch = (tryFunc, { catchValue = '' }) => {
  try {
    return tryFunc()
  } catch (err) {
    global.console.trace()
    global.console.error('TCL: tryCatch -> err', err)
    return catchValue
  }
}

export const getDishAttributes = () => categories[DISH_ATTRIBUTE]

export const loadScript = src => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = src
  document.body.appendChild(script)
}

export const numberWithCommas = (x = '') =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const pipe = functions => data => {
  return functions.reduce((value, func) => func(value), data)
}
