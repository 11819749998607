import React from 'react'
import ContentLoader from 'react-content-loader'
import withTheme from '@material-ui/styles/withTheme'
import PropTypes from 'prop-types'

class DishPageLoader extends React.Component {
  static propTypes = {
    theme: PropTypes.object.isRequired,
  }

  get containerPadding() {
    const { theme } = this.props
    return theme.containerPadding
  }

  get imageWidth() {
    return this.props.theme.windowWidth
  }

  get imageHeight() {
    return this.props.theme.windowWidth
  }

  get imageBottomMargin() {
    const { theme } = this.props
    return theme.spacing(2)
  }

  get titleOffset() {
    return this.imageHeight + this.imageBottomMargin
  }

  get titleHeight() {
    return 14
  }

  get titleBottomMargin() {
    return this.containerPadding
  }

  get descriptionOffset() {
    return this.titleOffset + this.titleHeight + this.titleBottomMargin
  }

  get descriptionHeight() {
    return 14
  }

  get descriptionBottomMargin() {
    return this.containerPadding
  }

  get attributesOffset() {
    return (
      this.descriptionOffset +
      this.descriptionHeight +
      this.descriptionBottomMargin
    )
  }

  get attributesHeight() {
    return 14
  }

  get attributesBottomMargin() {
    return this.containerPadding
  }

  get restaurantContainerOffset() {
    const { theme } = this.props
    return (
      theme.windowHeight -
      theme.restaurantContainerInDishPageBottom -
      theme.logoDimension
    )
  }

  get restaurantLogoDimensions() {
    const { theme } = this.props
    return theme.logoDimension
  }

  get restaurantLogoLeftMargin() {
    const { theme } = this.props
    return theme.spacing(1)
  }

  get restaurantNameOffset() {
    return this.offsetCenterOfRestaurantLogo
  }

  get restaurantNameOffsetRight() {
    return (
      this.containerPadding +
      this.restaurantLogoDimensions +
      this.restaurantLogoLeftMargin
    )
  }

  get restaurantNameHeight() {
    return 14
  }

  get restaurantNameWidth() {
    return this.restaurantLogoDimensions * 1.5
  }

  get dishCountOffset() {
    return this.offsetCenterOfRestaurantLogo
  }

  get dishCountHeight() {
    return 14
  }

  get offsetCenterOfRestaurantLogo() {
    return (
      this.restaurantContainerOffset +
      this.restaurantLogoDimensions / 2 -
      this.restaurantNameHeight
    )
  }

  renderImg() {
    const { dishImgHeight } = this.props.theme
    return (
      <rect
        rx="4"
        ry="4"
        width={String(this.imageWidth)}
        height={String(dishImgHeight)}
      />
    )
  }

  renderTitle() {
    return (
      <rect
        x={this.containerPadding}
        y={this.titleOffset}
        rx="4"
        ry="4"
        width={String(this.imageWidth * 0.33)}
        height={String(this.titleHeight)}
      />
    )
  }

  renderPrice() {
    const { windowWidth } = this.props.theme
    const priceWidth = 24
    return (
      <rect
        x={String(windowWidth - this.containerPadding - priceWidth)}
        y={this.titleOffset}
        rx="4"
        ry="4"
        width={String(priceWidth)}
        height={this.titleHeight}
      />
    )
  }

  renderDescription() {
    return (
      <rect
        x={this.containerPadding}
        y={this.descriptionOffset}
        rx="4"
        ry="4"
        width={String(this.imageWidth * 0.67)}
        height={String(this.descriptionHeight)}
      />
    )
  }

  renderAttributes() {
    return (
      <rect
        x={this.containerPadding}
        y={this.attributesOffset}
        rx="4"
        ry="4"
        width={String(this.imageWidth * 0.67)}
        height={String(this.attributesHeight)}
      />
    )
  }

  renderRestaurantLogo() {
    return (
      <rect
        x={this.containerPadding}
        y={this.restaurantContainerOffset}
        rx="4"
        ry="4"
        width={String(this.restaurantLogoDimensions)}
        height={String(this.restaurantLogoDimensions)}
      />
    )
  }

  renderRestaurantName() {
    return (
      <rect
        x={this.restaurantNameOffsetRight}
        y={this.restaurantNameOffset}
        rx="4"
        ry="4"
        width={String(this.restaurantLogoDimensions * 1.33)}
        height={String(14)}
      />
    )
  }

  renderDishCount() {
    const { windowWidth } = this.props.theme
    return (
      <rect
        x={windowWidth - this.containerPadding - this.restaurantLogoDimensions}
        y={this.dishCountOffset}
        rx="4"
        ry="4"
        width={String(this.restaurantLogoDimensions)}
        height={String(14)}
      />
    )
  }

  render() {
    const { windowHeight, windowWidth } = this.props.theme
    return (
      <ContentLoader
        rtl
        height={windowHeight}
        width={windowWidth}
        speed={1.3}
        primaryColor="#f0ebeb"
        secondaryColor="#d2d2d2"
      >
        {this.renderImg()}
        {this.renderTitle()}
        {this.renderPrice()}
        {this.renderDescription()}
        {this.renderAttributes()}
        {this.renderRestaurantLogo()}
        {this.renderRestaurantName()}
        {this.renderDishCount()}
      </ContentLoader>
    )
  }
}

export default withTheme(DishPageLoader)
