import { action, observable, reaction } from 'mobx'

import {
  NO_SEARCH_RESULTS_TEXT,
  CURRENT_LOCATION_LABEL,
} from '../constants/constants'
import { loadScript } from '../utils/utils'

const ERROR_CODE_LOCATION_DENIED_BY_USER = 1
const ERROR_CODE_LOCATION_NOT_SUPPORTED = 4

export default class LocationStore {
  googlePlacesAutocompleteService

  @observable locatingLoaderDotCount = 1

  @observable address = ''

  @observable latitude

  @observable longitude

  @observable isLocatingUser = false

  geoCoderPromise = Promise.resolve()

  constructor({ utils, localStorageManager }) {
    this.utils = utils
    this.localStorageManager = localStorageManager
  }

  @action.bound
  getLocationIfGrantedPermission() {
    const isGranted = this.localStorageManager.getLocationGranted()
    if (isGranted) {
      return this.requestAndSetUserLocation()
    }
    return Promise.resolve(false)
  }

  @action
  onSelectAddress({ label }) {
    if (label === CURRENT_LOCATION_LABEL) {
      return this.requestAndSetUserLocation()
    }
    this.address = label
    this.reportGeocodingStart({ label })
    this.geoCoderPromise = new Promise((resolve, reject) => {
      this.googleGeocoderService.geocode(
        { address: label },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat()
            const longitude = results[0].geometry.location.lng()
            this.reportGeodecodingSuccess({ latitude, longitude })
            this.latitude = latitude
            this.longitude = longitude
            resolve({ latitude, longitude })
          } else {
            this.reportGeodecodingErrorFor({ status })
            reject()
          }
        }
      )
    })
    return this.geoCoderPromise
  }

  @action
  onAddressInputChange(address) {
    this.address = address
  }

  @action
  resetAddress() {
    this.address = ''
  }

  @action.bound
  fetchAddressSuggestions = (address = '') => {
    this.geoCoderPromise = new Promise(resolve => {
      if (CURRENT_LOCATION_LABEL.startsWith(address)) {
        return resolve([this.currentLocationSuggestion])
      }
      if (!this.googlePlacesAutocompleteService) {
        return resolve([this.currentLocationSuggestion])
      }
      this.googlePlacesAutocompleteService.getPlacePredictions(
        {
          input: `תל אביב, ${address}`, // hack to only show tel aviv results :)
          componentRestrictions: { country: 'il' },
        },
        (predictions, status) => {
          if (status !== 'OK') {
            return resolve([this.currentLocationSuggestion])
          }
          let result = predictions.map(prediction => ({
            label: prediction.description,
            value: prediction.description,
          }))
          result = [this.currentLocationSuggestion, ...result]
          resolve(result)
        }
      )
    })
    return this.geoCoderPromise
  }

  requestUserLocation() {
    return new Promise(resolve => {
      if (!('geolocation' in navigator)) {
        global.alert('המכשיר אינו תומך במיקום')
        resolve({ error: { code: ERROR_CODE_LOCATION_NOT_SUPPORTED } })
        return
      }
      navigator.geolocation.getCurrentPosition(
        position => {
          resolve({ position })
        },
        error => {
          resolve({ error })
        }
      )
    })
  }

  @action
  async requestAndSetUserLocation() {
    this.isLocatingUser = true
    const { position, error } = await this.requestUserLocation()
    this.isLocatingUser = false
    if (error) {
      if (error.code === ERROR_CODE_LOCATION_DENIED_BY_USER) {
        this.localStorageManager.setLocationGranted(false)
        global.alert(
          'אנא אשר/י שימוש במיקום הנוכחי על מנת לחפש מנות טבעוניות בקרבתך'
        )
      } else if (error.code === ERROR_CODE_LOCATION_NOT_SUPPORTED) {
        global.alert('המכשיר אינו תומך מיקום')
      } else {
        global.alert('ארעה שגיאה, אנא נסה/י שנית')
      }
      return false
    }
    this.localStorageManager.setLocationGranted(true)
    this.latitude = position.coords.latitude
    this.longitude = position.coords.longitude
    this.address = CURRENT_LOCATION_LABEL
    return true
  }

  @action
  hydrateState({ address, latitude, longitude }) {
    if (latitude && longitude) {
      this.latitude = latitude
      this.longitude = longitude
    }
    if (address) {
      this.address = address
    }
  }

  @action.bound
  loadGooglePlacesService() {
    window.googleMapsLoadCallback = this.googleMapsLoadCallback
    if (
      typeof window.google === 'object' &&
      typeof window.google.maps === 'object'
    ) {
      if (!this.utils.env.isDev()) {
        global.console.warn('Google service api tried to load multiple times')
      }
      return
    }
    const src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCg6qDVkthZAfljBrS02HWuV2wdQ_JCy0g&libraries=places&language=iw&callback=googleMapsLoadCallback'
    loadScript(src)
  }

  googleMapsLoadCallback = () => {
    this.googlePlacesAutocompleteService = new window.google.maps.places.AutocompleteService()
    this.reportAutoCompleteLoaded()
    this.googleGeocoderService = new window.google.maps.Geocoder()
    this.reportGeocoderLoaded()
  }

  locatingLoaderReaction = reaction(
    () => this.isLocatingUser,
    isLocatingUser => {
      if (isLocatingUser) {
        this.startLocatingLoaderInterval()
      } else {
        this.stopLocatingLoaderInterval()
      }
    }
  )

  startLocatingLoaderInterval() {
    this.locatingLoaderInterval = setInterval(this.onLocatingLoaderTick, 350)
  }

  stopLocatingLoaderInterval() {
    clearInterval(this.locatingLoaderInterval)
  }

  onLocatingLoaderTick = () => {
    this.locatingLoaderDotCount =
      (this.locatingLoaderDotCount + 1) % (this.numberOfDots + 1)
  }

  get addressDisplay() {
    return this.isLocatingUser
      ? `מאתר מיקום${'.'.repeat(this.locatingLoaderDotCount)}`
      : this.address
  }

  get currentLocationSuggestion() {
    return { label: CURRENT_LOCATION_LABEL }
  }

  get NO_OPTIONS_LABEL() {
    return NO_SEARCH_RESULTS_TEXT
  }

  get CURRENT_LOCATION_LABEL() {
    return CURRENT_LOCATION_LABEL
  }

  get isCurrentLocationSelected() {
    return CURRENT_LOCATION_LABEL === this.address
  }

  get numberOfDots() {
    return 3
  }

  get gotUserLocation() {
    return !!(this.latitude && this.longitude)
  }

  get locationIsPresent() {
    return this.gotUserLocation || this.address
  }

  /*
   * Logging
   */

  reportGeocodingStart = ({ label }) => {
    global.console.debug('[Geo decoding] -> Start for label -> ', label)
  }

  reportGeodecodingSuccess({ latitude, longitude }) {
    global.console.debug(
      '[Geo decoding] -> Resolved -> coords ->',
      latitude,
      longitude
    )
  }

  reportGeodecodingErrorFor = ({ status }) => {
    global.console.warn('[Geo decoding] -> Rejected ->', status)
  }

  reportAutoCompleteLoaded = () =>
    global.console.debug('[Google Autocomplete Service] Loaded')

  reportGeocoderLoaded = () =>
    global.console.debug('[Google Geocoder Service] Loaded')
}
