import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import Header from '../components/Header'

class HomePage extends Component {
  render() {
    return (
      <div>
        <Redirect to="/restaurants" />
        <Header />
        <Typography variant="h6">Plate in the house</Typography>
      </div>
    )
  }
}

export default HomePage
