import { theme } from './ThemeProvider'
import { API_BASE_URL } from './constants/constants'
import history from './utils/history'
import ApiService from './services/ApiService'
import FilterService from './services/FilterService'
import wbService from './services/wbService'
import SerializerService from './services/SerializerService'
import CacheService from './services/CacheService'
import RequestService from './services/RequestService'
import createUtils from './utils/createUtils'
import createHelpers from './helpers/createHelpers'
import createManagers from './managers/createManagers'
import createStores from './stores/createStores'
import createFacade from './facade/createFacade'
import createMetas from './metas/createMetas'

import './bootstrap'

const utils = createUtils()
const helpers = createHelpers({ utils })
const managers = createManagers({ utils, helpers })

const filterService = new FilterService()
const serializerService = new SerializerService()
const apiService = new ApiService({
  helpers,
  utils,
  serializerService,
  baseUrl: API_BASE_URL,
})
const cacheService = new CacheService({ filterService })
const requestService = new RequestService({ apiService, cacheService })
const stores = createStores({
  utils,
  helpers,
  apiService,
  serializerService,
  localStorageManager: managers.localStorageManager,
  cacheService,
  requestService,
  history,
})

const facade = createFacade({
  managers,
  helpers,
  utils,
  stores,
  serializerService,
  apiService,
  cacheService,
  requestService,
  history,
})

const metas = createMetas({ facade, stores, requestService })

reportFacade()

if (!utils.env.isProd()) {
  global.facade = facade
  global.theme = theme
}

metas.booting
  .boot()
  .then(metas.rendering.render)
  .then(metas.booting.preload)

// must be called after all listeners have been set up, read more at https://developers.google.com/web/tools/workbox/modules/workbox-window
wbService.register()

reportIndexParsed()

if (module.hot) {
  module.hot.accept()
}

/*
 * Logging
 */

function reportFacade() {
  global.console.debug('[Facade]', facade)
}

function reportIndexParsed() {
  global.console.debug('[index] -> Parsed')
}
