import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/styles/withStyles'

class DishAttributes extends Component {
  static propTypes = {
    attributesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { attributesNames, classes } = this.props
    return attributesNames.map(attr => (
      <Chip className={classes.attribute} key={attr} label={attr} />
    ))
  }
}

const styles = theme => ({
  attribute: {
    marginLeft: theme.spacing(1 / 2),
    height: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
})

export default withStyles(styles)(DishAttributes)
