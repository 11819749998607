import { action } from 'mobx'
import orderByDistance from 'geolib/es/orderByDistance'

import WebWorker from '../webWorkers/WebWorker'
import FilterWorker from '../webWorkers/filter.worker'
import { DISH_ATTRIBUTES, RESTAURANT_ATTRIBUTES } from '../constants/constants'

class CacheService {
  restaurants = []

  constructor() {
    this.filterWorker = new WebWorker(FilterWorker)
    this.filterWorker.postMessage({
      init: {
        DISH_ATTRIBUTES,
        RESTAURANT_ATTRIBUTES,
      },
    })
  }

  @action
  getRestaurantList(params) {
    return new Promise((resolve, reject) => {
      this.filterWorker.removeEventListener(
        'message',
        this.filterWorkerMessageListener
      )
      this.filterWorker.removeEventListener(
        'error',
        this.filterWorkerErrorListener
      )
      this.filterWorkerMessageListener = evt => {
        const restaurantList = this.sortDistanceIfNeeded(params, evt.data)
        resolve({ data: { restaurantList } })
      }
      this.filterWorker.addEventListener(
        'message',
        this.filterWorkerMessageListener,
        false
      )
      this.filterWorkerErrorListener = error => {
        this.reportError('recieved from filter worker', error)
        reject(error)
      }
      this.filterWorker.addEventListener(
        'error',
        this.filterWorkerErrorListener,
        false
      )
      this.filterWorker.postMessage({ params, restaurants: this.restaurants })
    })
  }

  @action
  getRestaurant({ id, supressConsole }) {
    if (!supressConsole) {
      this.reportGetRestaurant(id)
    }
    const restaurant = this.restaurants.find(rest => rest.id === id)
    if (!restaurant) {
      if (!supressConsole) {
        this.reportRestaurantNotFound(id)
      }
      return { errorMessage: 'לא קיימת מסעדה כזאת' }
    }
    if (!supressConsole) {
      this.reportGetRestaurantSuccess(restaurant)
    }
    return { data: restaurant }
  }

  @action
  getDish = ({ id, restaurantId }) =>
    restaurantId
      ? this.getDishByRestaurantId({ id, restaurantId })
      : this.getDishByIdOnly({ id })

  @action
  getDishByIdOnly = ({ id }) => {
    this.reportGetDishByIdOnly(id)
    let dish
    // eslint-disable-next-line array-callback-return
    this.restaurants.find(restaurant => {
      dish = restaurant.dishes.find(d => d.id === id)
      return dish && true // break outer loop
    })
    if (!dish) {
      this.reportDishNotFoundById(id)
      return { errorMessage: 'לא קיימת מנה כזאת' }
    }
    return { data: dish }
  }

  @action
  getDishByRestaurantId = ({ id, restaurantId }) => {
    this.reportGetDishByRestaurantId({ id, restaurantId })
    const { data: restaurant, errorMessage } = this.getRestaurant({
      id: restaurantId,
      supressConsole: true,
    })
    if (errorMessage) {
      this.reportRestaurantNotFoundForDish({ id, restaurantId })
      return { errorMessage: 'לא קיימת מסעדה למנה הזאת' }
    }
    const dish = restaurant.dishes.find(d => d.id === id)
    if (!dish) {
      this.reportDishNotFoundByRestaurantId({ id, restaurantId })
      return { errorMessage: 'לא קיימת מנה כזאת' }
    }
    this.reportGotDish(dish)
    return { data: dish }
  }

  sortDistanceIfNeeded(params, restaurantList) {
    return this.restaurantListQueryHasLocation(params)
      ? orderByDistance(params.location, restaurantList)
      : restaurantList
  }

  restaurantListQueryHasLocation(params) {
    const { latitude, longitude } = params.location
    return !!(latitude && longitude)
  }

  @action
  hydrate({ restaurants, categories }) {
    this.restaurants = restaurants
    this.categories = categories
  }

  get isActive() {
    return true
  }

  /*
   * Logging
   */

  reportError = (...args) =>
    global.console.error('[Cache Service] reportError: ', ...args)

  debug = (...args) => global.console.debug('[Cache Service] debug: ', ...args)

  reportGetRestaurant = id =>
    global.console.debug(`[Cache Service] -> Getting restaurant ${id}`)

  reportRestaurantNotFound = id =>
    global.console.warn(`[Cache Service] -> Restaurant ${id} not found`)

  reportGetRestaurantSuccess = restaurant =>
    global.console.debug('[Cache Service] -> Got restaurant ->', restaurant)

  reportGetDishByIdOnly = id =>
    global.console.debug(`[Cache Service] -> Getting dish ${id}`)

  reportDishNotFoundById = id =>
    global.console.debug(`[Cache Service] -> Dish ${id} not found`)

  reportGetDishByRestaurantId = ({ id, restaurantId }) =>
    global.console.debug(
      `[Cache Service] -> Getting dish ${id} by restaurant ${restaurantId}`
    )

  reportRestaurantNotFoundForDish = ({ id, restaurantId }) =>
    global.console.warn(
      `[Cache Service] Restaurant ${restaurantId} not found for dish ${id}`
    )

  reportDishNotFoundByRestaurantId = ({ id, restaurantId }) =>
    global.console.warn(
      `[Cache Service] Dish ${id} not found by restaurant ${restaurantId}`
    )

  reportGotDish = dish =>
    global.console.debug('[Cache Service] -> Got dish', dish)
}

export default CacheService
