import history from './history'

export const routeBack = () => history.goBack()

/*
 * get path for internal links
 */
export const homeRoute = () => '/'

export const dishRoute = ({ id = ':id' } = {}) => `/dishes/${id}`

export const searchRoute = () => '/search'

export const restaurantsRoute = ({ queryParams = '' } = {}) =>
  `/restaurants${queryParams}`

export const restaurantRoute = ({ id = ':id' } = {}) => `/restaurants/${id}`

export const dishInRestaurantRoute = ({ restaurantId, dishId }) =>
  `/restaurants/${restaurantId}#${dishId}`

/*
 * check if it's a specific route
 */
export const isHomeRoute = (pathname = '') => pathname === '/'
export const isSearchRoute = (pathname = '') => pathname === '/search'
export const isRestaurantListRoute = (pathname = '') =>
  pathname === '/restaurants'
export const isRestaurantRoute = (pathname = '') =>
  !!pathname.match(/restaurants\/\d/)
export const isDishRoute = (pathname = '') => !!pathname.match(/dishes\/\d/)

/*
 * programatically change route
 */
export const routeToDish = ({ id }) => history.push(dishRoute({ id }))

export const routeToSearch = () => history.push(searchRoute())

export const routeToRestaurant = ({ id }) =>
  history.push(restaurantRoute({ id }))

export const routeToDishInRestaurant = ({ restaurantId, dishId }) =>
  history.push(dishInRestaurantRoute({ restaurantId, dishId }))

export const routeToRestaurants = ({ queryParams }) =>
  history.push(restaurantsRoute({ queryParams }))

export const replaceRouteToRestaurants = ({ queryParams }) =>
  history.replace(restaurantsRoute({ queryParams }))
