import { Workbox } from 'workbox-window'
import noop from 'lodash/noop'

import { isDev, isSwForcedInDev } from '../utils/utils'

let wb = { doesExists: false, register: noop } // eslint-disable-line import/no-mutable-exports

init()

function init() {
  if (!('serviceWorker' in navigator)) {
    return
  }
  if (isDev && !isSwForcedInDev) {
    // see README.md about service worker in dev
    return
  }
  initWb()
}

function initWb() {
  if (window.location.search.includes('WORKBOX_DEBUG')) {
    wb = new Workbox('service-worker.js?debug') // defualt name from CRA's build
  } else {
    wb = new Workbox('service-worker.js') // defualt name from CRA's build
  }
  wb.addEventListener('installed', event => {
    // `event.isUpdate` will be true if another version of the service
    // worker was controlling the page when this version was registered.
    if (!event.isUpdate) {
      global.console.log('[wbService]: service worker first install', event)
    }
  })

  wb.addEventListener('activated', event => {
    global.console.log('[wbService]: service worker activated', event)
    if (event.isUpdate) {
      global.console.log(
        '[wbService]: new service working activated, reloading the page'
      )
      window.location.reload()
    }
  })

  wb.addEventListener('externalinstalled', event => {
    global.console.log('[wbService]: service worker externalinstalled', event)
  })
  wb.addEventListener('externalwaiting', event => {
    global.console.log('[wbService]: service worker externalwaiting', event)
  })
  wb.addEventListener('externalactivated', event => {
    global.console.log('[wbService]: service worker externalactivated', event)
  })

  wb.addEventListener('message', event => {
    global.console.log('[wbService]: message', event)
    if (event.data.type === 'CACHE_UPDATED') {
      const { updatedURL } = event.data.payload

      global.console.log(
        `[wbService]: A newer version of ${updatedURL} is available!`
      )
    }
  })
}

export default wb
