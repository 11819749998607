/* eslint-disable jsx-a11y/anchor-has-content */ // Create react app prints a warning even tho it is disabled in eslintrc
import React, { Component } from 'react'

class ExternalLink extends Component {
  render() {
    return (
      <a
        {...this.props}
        style={{ textDecoration: 'none' }}
        target="_blank"
        rel="noopener noreferrer"
      />
    )
  }
}

export default ExternalLink
