import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/styles/withStyles'

class Container extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const { classes, className, ...restOfProps } = this.props
    return <div className={cx(className, classes.root)} {...restOfProps} />
  }
}

const styles = theme => ({
  root: {
    paddingRight: theme.containerPadding,
    paddingLeft: theme.containerPadding,
    position: 'relative',
    width: '100vw',
  },
})
export default withStyles(styles)(Container)
