import S3Helper from './S3.helper'
import DistanceHelper from './Distance.helper'

const createHelpers = ({ utils }) => {
  const s3 = new S3Helper({ utils })
  const distance = new DistanceHelper({ utils })

  return {
    s3,
    distance,
  }
}

export default createHelpers
