import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import isBoolean from 'lodash/isBoolean'
import isUndefined from 'lodash/isUndefined'
import qs from 'qs'

import { decodeURISafely } from '../utils/utils'
import {
  ONLY_VEGAN,
  KOSHER,
  OPEN_ON_SATURDAY,
  HAS_DELIVERY,
  OPEN_NOW,
  DISH_ATTRIBUTES,
} from '../constants/constants'

export default class SerializerService {
  serializeState({ states }) {
    const paramsObj = this.stateToParamsObj(states)
    return this.serialize(paramsObj)
  }

  serializeRestaurant({ location }) {
    return this.serialize({ location })
  }

  serialize(paramsObj) {
    const serialized = this.serializeObject(paramsObj)
    return !serialized ? '' : `?${serialized}`
  }

  mapEntries = ([key, value], parentObjKey = '') => {
    if (isString(value)) {
      return this.serializeString({ key, value, parentObjKey })
    }
    if (isNumber(value)) {
      return this.serializeNumber({ key, value, parentObjKey })
    }
    if (isArray(value)) {
      return this.serializeArray({ key, value, parentObjKey })
    }
    if (isPlainObject(value)) {
      return this.serializeObject(value, key)
    }

    if (isBoolean(value)) {
      return this.serializeBoolean({ key, value, parentObjKey })
    }
  }

  serializeString({ key, value, parentObjKey }) {
    if (!value) {
      return
    }
    return parentObjKey ? `${parentObjKey}[${key}]=${value}` : `${key}=${value}`
  }

  serializeNumber({ key, value, parentObjKey }) {
    if (isUndefined(value)) {
      return
    }
    return parentObjKey ? `${parentObjKey}[${key}]=${value}` : `${key}=${value}`
  }

  serializeArray({ key, value, parentObjKey }) {
    if (!value.length) {
      return
    }
    return parentObjKey
      ? `${parentObjKey}[${key}]=${value.join(',')}`
      : `${key}=${value.join(',')}`
  }

  serializeBoolean({ key, value, parentObjKey }) {
    if (!value) {
      return
    }
    return parentObjKey ? `${parentObjKey}[${key}]=${value}` : `${key}=${value}`
  }

  // for filter={ foo: 'bar' } - parentObjKey would be "filter", and obj would be { foo: 'bar' }
  serializeObject(obj, parentObjKey) {
    return Object.entries(obj)
      .map(entries => this.mapEntries(entries, parentObjKey))
      .filter(Boolean)
      .join('&')
  }

  stateToParamsObj({ filters, location = {} }) {
    const paramsObj = {
      filters: {
        restAttrs: this.getActiveRestaurantAttributes(filters),
        dishAttrs: this.getActiveDishAttributes(filters),
        dishCatId: filters.dishTypeId,
        searchQuery: filters.searchQuery,
      },
      location,
    }
    if (filters[OPEN_NOW]) {
      paramsObj.filters[OPEN_NOW] = filters[OPEN_NOW]
    }
    return paramsObj
  }

  getActiveRestaurantAttributes(filters) {
    const attributes = []
    if (filters[ONLY_VEGAN]) {
      attributes.push(ONLY_VEGAN)
    }
    if (filters[KOSHER]) {
      attributes.push(KOSHER)
    }
    if (filters[OPEN_ON_SATURDAY]) {
      attributes.push(OPEN_ON_SATURDAY)
    }
    if (filters[HAS_DELIVERY]) {
      attributes.push(HAS_DELIVERY)
    }

    return attributes
  }

  getActiveDishAttributes(filters) {
    const attributes = []
    DISH_ATTRIBUTES.forEach(filternameKey => {
      if (filters[filternameKey]) {
        attributes.push(filternameKey)
      }
    })
    return attributes
  }

  deserializeQueryParams() {
    const str = `${window.location.search}&` // adding & for the regex below
    const parsedFilters = qs.parse(str.replace('?', '')).filters || {}
    const parsedLocation = qs.parse(str.replace('?', '')).location || {}
    const { dishCatId, openNow } = parsedFilters
    let { address = '', latitude = '', longitude = '' } = parsedLocation
    let { dishAttrs = '', restAttrs = '', searchQuery = '' } = parsedFilters
    searchQuery = decodeURISafely(searchQuery)
    address = decodeURISafely(address)
    latitude = decodeURISafely(latitude)
    longitude = decodeURISafely(longitude)
    dishAttrs = dishAttrs ? dishAttrs.split(',') : []
    restAttrs = restAttrs ? restAttrs.split(',') : []
    const states = {
      filters: {},
    }
    if (dishCatId) states.dishCatId = Number(dishCatId)
    if (searchQuery) states.searchQuery = searchQuery
    restAttrs.forEach(restAttr => {
      states.filters[restAttr] = true
    })
    if (openNow) states.filters[OPEN_NOW] = true
    if (latitude && longitude) {
      states.latitude = latitude
      states.longitude = longitude
    }
    if (address) {
      states.address = address
    }
    if (dishAttrs.length) {
      dishAttrs.forEach(attr => {
        states.filters[attr] = true
      })
    }
    return states
  }

  deserializeString(str, key) {
    const regex = new RegExp(`${key}=(.*?)&`)
    const match = str.match(regex)
    return match ? match[1] : ''
  }

  deserializeArray(str, key) {
    const string = this.deserializeString(str, key)
    return string && string.split(',')
  }

  deserializeObject(str, parentObjKey) {
    const regex = new RegExp(`[${parentObjKey}]=(.*?)&`)
    const match = str.match(regex)
    return match
  }
}
