import FiltersStore from './FiltersStore'
import DishTypeStore from './DishTypeStore'
import RestaurantListStore from './RestaurantListStore'
import SearchStore from './SearchStore'
import LocationStore from './LocationStore'
import RestaurantStore from './RestaurantStore'
import DishStore from './DishStore'

const createStores = ({
  utils,
  apiService,
  requestService,
  localStorageManager,
}) => {
  const filtersStore = new FiltersStore({ apiService })
  const dishTypeStore = new DishTypeStore({ apiService })
  const restaurantListStore = new RestaurantListStore({ requestService })
  const searchStore = new SearchStore({ apiService })
  const locationStore = new LocationStore({ utils, localStorageManager })
  const restaurantStore = new RestaurantStore({ requestService })
  const dishStore = new DishStore({ requestService })
  return {
    filtersStore,
    dishTypeStore,
    restaurantListStore,
    searchStore,
    restaurantStore,
    locationStore,
    dishStore,
  }
}

export default createStores
