import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import HomePage from './pages/HomePage'
import RestaurantListPage from './pages/RestaurantListPage'
import RestaurantPage from './pages/RestaurantPage'
import DishPage from './pages/DishPage'
import SearchPage from './pages/SearchPage'
import {
  homeRoute,
  searchRoute,
  restaurantsRoute,
  restaurantRoute,
  dishRoute,
} from './utils/routing'

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path={homeRoute()} exact component={HomePage} />
        <Route path={searchRoute()} exact component={SearchPage} />
        <Route path={restaurantsRoute()} exact component={RestaurantListPage} />
        <Route path={restaurantRoute()} exact component={RestaurantPage} />
        <Route path={dishRoute()} exact component={DishPage} />
      </Switch>
    )
  }
}

export default Routes
