import React from 'react'
import PropTypes from 'prop-types'
import { Provider, observer } from 'mobx-react'
import CssBaseline from '@material-ui/core/CssBaseline'

import App from '../App'

@observer
class Root extends React.Component {
  static propTypes = {
    stores: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
  }

  render() {
    return (
      <Provider {...this.props.stores} facade={this.props.facade}>
        <React.Fragment>
          <CssBaseline />
          <App />
        </React.Fragment>
      </Provider>
    )
  }
}

export default Root
