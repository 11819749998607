import React, { Component } from 'react'
import cx from 'classnames'
import { PropTypes } from 'prop-types'
import { observer, inject } from 'mobx-react'
import withStyles from '@material-ui/styles/withStyles'

import Filter from './Filter'

@inject('facade', 'filtersStore')
@observer
class Filters extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filtersStore: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
  }

  renderFilter = filter => (
    <Filter
      key={filter.value}
      isOn={this.props.filtersStore.filters[filter.value]}
      {...filter}
    />
  )

  render() {
    const { classes, facade, filtersStore } = this.props
    return (
      <div
        className={cx(classes.root, 'no-scrollbar', {
          [classes.isScrollingDown]:
            facade.restaurantListVerticalScrollDirectionIsDown,
        })}
        style={{
          transform: `translateY(${-Math.max(
            facade.restaurantListVerticalScrollPosition - 30,
            0
          )}px)`,
        }}
      >
        <ul className={classes.list}>
          {filtersStore.filtersToRender.map(this.renderFilter)}
          {/* hack for adding padding at the end of the list */}
          <li> &nbsp;&nbsp;&nbsp; </li>
        </ul>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.containerPadding - 4,
    paddingLeft: theme.containerPadding - 4,
    paddingBottom: theme.spacing(1),
    flexWrap: 'nowrap',
    overflowX: 'overlay',
    height: theme.filters.height,
  },
  // isScrollingDown: {
  //   paddingTop: 0,
  //   paddingBottom: 0,
  //   height: 0,
  // },
  list: {
    margin: 0,
    padding: 0,
    justifyContent: 'space-between',
    display: 'flex',
    listStyleType: 'none',
  },
})

export default withStyles(styles)(Filters)
