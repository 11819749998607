import axios from 'axios'
import { action, computed } from 'mobx'
import camelCaseKeys from 'camelcase-keys'
import camelCase from 'camelcase'

const getSuggestionsApiEndpoint = ({ queryParams = '' } = {}) =>
  `/suggestions${queryParams}`

const getRestaurantListApiEndpoint = ({ queryParams = '' } = {}) =>
  `/restaurants${queryParams}`

const getRestaurantApiEndpoint = ({ id = '', queryParams = '' } = {}) =>
  `/restaurants/${id}${queryParams}`

const getDishApiEndpoint = ({ id = '' } = {}) => `/dishes/${id}`

export default class ApiService {
  baseUrl = ''

  constructor({ helpers, serializerService, baseUrl }) {
    this.helpers = helpers
    this.serializerService = serializerService
    this.baseUrl = baseUrl
  }

  @action
  fetchAllData = () => {
    return this.get(this.helpers.s3.getAllRestaurantsUrl(), {
      headers: this.noCacheHeaders,
    })
  }

  @action
  fetchAllCategories = () =>
    this.get(this.helpers.s3.getAllCategoriesUrl(), {
      headers: this.noCacheHeaders,
    })

  @action
  fetchSuggestions = ({ states }) =>
    this.get(
      getSuggestionsApiEndpoint({
        queryParams: this.serializerService.serializeState({ states }),
      })
    )

  @action
  fetchRestaurantList = states =>
    this.get(
      getRestaurantListApiEndpoint({
        queryParams: this.serializerService.serializeState({ states }),
      })
    )

  @action
  fetchRestaurant = ({ id, states }) =>
    this.get(
      getRestaurantApiEndpoint({
        id,
        queryParams: this.serializerService.serializeRestaurant(states),
      })
    )

  @action
  fetchDish = ({ id }) => this.get(getDishApiEndpoint({ id }))

  buildUrl = path => {
    // temporary hack until refactoring some api service's logic
    if (path.includes('http')) {
      return path
    }
    return this.baseUrl + this.snakeCasePath(path)
  }

  get(path, config = {}) {
    return axios
      .get(this.buildUrl(path), config)
      .then(res => {
        if (!this.isBadResponse(res)) {
          return res
        }
        this.reportBadResponse({ path, config, res })
        return {
          data: {}, // allow consumers to safetly destrucuture data properties
          error: 'bad response from API',
          errorMessage: 'bad response from api',
        }
      })
      .then(this.camelCaseResponse)
      .catch(error => {
        this.reportError({ path, config, error })
        return { errorMessage: error.message, errorObject: error }
      })
  }

  snakeCasePath(path) {
    return path
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase()
  }

  camelCaseResponse = res => {
    if (!res || !res.data) {
      return res
    }
    if (res.data.meta) {
      res.data.meta = camelCaseKeys(res.data.meta)
    }
    // fetch all restaurants
    if (res.data.restaurants) {
      res.data.restaurants = this.camelCaseAllRestaurant(res.data.restaurants)
    }
    // restaurants search suggestions
    if (res.data.restaurants) {
      res.data.restaurants = camelCaseKeys(res.data.restaurants)
    }
    // dishes search suggestions
    if (res.data.dishes) {
      res.data.dishes = res.data.dishes.map(camelCaseKeys)
    }
    // categories search suggestions
    if (res.data.categories) {
      res.data.categories = res.data.categories.map(this.camelCaseCategory)
    }

    res.data = camelCaseKeys(res.data)
    return res
  }

  camelCaseAllRestaurant(allRestaurants) {
    return allRestaurants.map(this.camelCaseRestaurant)
  }

  camelCaseRestaurant = restaurant => {
    if (!restaurant.dishes) {
      this.reportUndefinedRestaurantDishes(restaurant)
      return { ...restaurant, dishes: [] }
    }
    return {
      ...camelCaseKeys(restaurant),
      dishes: restaurant.dishes.map(camelCaseKeys),
    }
  }

  camelCaseCategory = category => ({
    ...camelCaseKeys(category),
    type: camelCase(category.type),
  })

  @computed
  get noCacheHeaders() {
    const headers = new Headers()
    headers.append('pragma', 'no-cache')
    headers.append('cache-control', 'no-cache')
    return headers
  }

  isBadResponse(res) {
    return (
      !res ||
      res.data === undefined ||
      res.data === null ||
      Number.isNaN(res.data)
    )
  }

  reportUndefinedRestaurantDishes(restaurant) {
    window.console.warn('API restaurant has no dishes', restaurant)
  }

  reportBadResponse({ path, config, res }) {
    window.console.group('API bad response')
    window.console.error(
      'path:',
      path,
      '\n',
      'config',
      config,
      '\n',
      'response',
      res
    )
    window.console.groupEnd()
  }

  reportError({ path, config, error }) {
    window.console.group('API error')
    window.console.error(
      'path:',
      path,
      '\n',
      'config',
      config,
      '\n',
      'error',
      error
    )
    window.console.groupEnd()
  }
}
