import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Helmet from 'react-helmet'

import { ILS, LOADING, ERROR, LOADED } from '../constants/constants'
import { imgPlaceholder } from '../utils/utils'
import Container from '../components/Container'
import Logo from '../components/Logo'
import DishAttributes from '../components/DishAttributes'
import DishPageLoader from '../components/DishPageLoader'

@inject('dishStore', 'facade')
@observer
class DishPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    dishStore: PropTypes.shape({
      state: PropTypes.string.isRequired,
      attributesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      dish: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
        imageUrl: PropTypes.string,
        menuCategoryName: PropTypes.string,
        restaurantName: PropTypes.string,
        restaurantId: PropTypes.number,
        restaurantDishCount: PropTypes.number,
        RestaurantLogoUrl: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }

  validatePictureMode() {
    const {
      facade,
      dishStore: { dish },
    } = this.props
    if (facade.isPictureMode) {
      return
    }
    global.console.warn('[Dish page] Not in picture mode, redirecting ...')
    facade.routeToDishInRestaurant({
      dishId: dish.id,
      restaurantId: dish.restaurantId,
    })
  }

  render() {
    const {
      classes,
      facade,
      dishStore: {
        state,
        errorMessage,
        attributesNames,
        dish: {
          name,
          description,
          price,
          imageUrl,
          restaurantName,
          restaurantId,
          restaurantDishCount,
          restaurantLogoUrl,
        },
      },
    } = this.props
    if (state === LOADING || facade.fetchAllDataState === LOADING) {
      return <DishPageLoader />
    }
    if (state === ERROR) {
      return <Typography>{errorMessage}</Typography>
    }
    if (state === LOADED) {
      this.validatePictureMode()
    }
    return (
      <div className={classes.root}>
        <Helmet>
          <meta name="robots" content="NOINDEX, NOFOLLOW" />
        </Helmet>
        <div className={classes.heroImgContainer}>
          <img
            className={classes.heroImg}
            src={imageUrl || imgPlaceholder(400)}
            alt={name}
          />
        </div>
        <Container>
          <div className={classes.nameAndPrice}>
            <Typography variant="h6" component="h1">
              {name}
            </Typography>
            <Typography>
              {price} {ILS}
            </Typography>
          </div>
          <Typography className={classes.description}>{description}</Typography>
          <DishAttributes attributesNames={attributesNames} />
        </Container>
        <Container className={classes.restaurantContainer}>
          <div
            className={classes.restaurant}
            onClick={() => facade.routeToRestaurant({ id: restaurantId })}
          >
            <div className={classes.restaurnatBrand}>
              <Logo name={restaurantName} src={restaurantLogoUrl} />
              <Typography variant="h2">{restaurantName}</Typography>
            </div>
            <Typography variant="h2" className={classes.dishCount}>
              {restaurantDishCount} מנות &gt;
            </Typography>
          </div>
        </Container>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(1),
    height: '100vh',
  },
  heroImgContainer: {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroImg: {
    height: theme.dishImgHeight,
    width: theme.dishImgWidth,
    objectFit: 'cover',
  },
  nameAndPrice: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
  },
  description: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(1),
  },
  restaurantContainer: {
    position: 'absolute',
    bottom: theme.bottomBarHeight + theme.spacing(2),
  },
  restaurant: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  restaurnatBrand: {
    display: 'flex',
    alignItems: 'center',
  },
  dishCount: {
    whiteSpace: 'nowrap',
  },
})

export default withStyles(styles)(DishPage)
