import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/styles/withStyles'
import withTheme from '@material-ui/styles/withTheme'
import Typography from '@material-ui/core/Typography'
import Scrollspy from 'react-scrollspy'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import 'gsap/CSSPlugin'
import TimelineMax from 'gsap/TimelineMax'
import TweenLite, { Power2 } from 'gsap/TweenLite'
import LanguageIcon from '@material-ui/icons/Language'
import PhoneIcon from '@material-ui/icons/Phone'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import ShareIcon from '@material-ui/icons/Share'
import Helmet from 'react-helmet'

import DeliveryIcon from '../components/DeliveryIcon'
import WoltIcon from '../components/WoltIcon'
import TenbisIcon from '../components/TenbisIcon'
import MishlohaIcon from '../components/MishlohaIcon'
import MapIcon from '../components/MapIcon'
import Rating from '../components/Rating'
import ExternalLink from '../components/ExternalLink'
import { ERROR, LOADING, ILS, LOADED } from '../constants/constants'
import { numberWithCommas } from '../utils/utils'
import OpeningHours from '../components/OpeningHours'
import { googlePlaceReivewLink, googleMapsPlaceLink } from '../utils/google'
import WalkingTime from '../components/WalkingTime'
import Container from '../components/Container'
import Logo from '../components/Logo'
import DishAttributes from '../components/DishAttributes'
import Card from '../components/Card'
import DishComfySlider from '../components/DishComfySlider'

const MENU_CATEGORY_NAV_HEIGHT = 40
const DISH_IMG_DIMENSIONS = '100vw'

@inject('facade', 'restaurantStore')
@observer
class RestaurantPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    restaurantStore: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  dishesEls = []

  tl = new TimelineMax()

  componentDidMount() {
    global.dishesEls = this.dishesEls
    this.scrollToDishIfNeeded()
  }

  componentDidUpdate() {
    this.scrollToDishIfNeeded()
    this.animateDishesIfNeeded()
  }

  get menuCategoriesOffset() {
    const { theme } = this.props
    return theme.headerHeight + MENU_CATEGORY_NAV_HEIGHT
  }

  get restaurantIsLoaded() {
    const { restaurantStore } = this.props
    // for some reason the first componentDidUpdate already has prevProps of restaurantStore.state === LOADED,
    // even tho componentDidMout has restaurantStore.state === LOADING.
    // for now testing only current state works, but if other prop changes will occour in the page, this will scroll again to the linked element, which isn't good ...
    // this was originally written as prevProps.restaurantStore.state === LOADING && this.props.restaurantStore.state === LOADED
    return restaurantStore.state === LOADED
  }

  handleFacebookClick = e => {
    e.preventDefault()
    const { facebook } = this.props.restaurantStore.restaurant
    setTimeout(function() {
      window.location = `https://www.facebook.com/${facebook}`
    }, 25)
    window.location = `fb://page/${facebook}`
  }

  handleShareClick = e => {
    e.preventDefault()
    const { id, name } = this.props.restaurantStore.restaurant
    const title = `Plate | מסעדת ${name}`
    const url = `https://plate.io/restaurants/${id}`
    if (navigator.share) {
      navigator
        .share({ title, url })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error))
    }
  }

  animateDishesIfNeeded() {
    if (!this.restaurantIsLoaded) {
      return
    }
    this.animateDishes()
  }

  animateDishes() {
    const { restaurantStore, facade } = this.props
    if (!facade.isPictureMode) {
      restaurantStore.restaurant.dishes.forEach((dish, idx) => {
        TweenLite.from(document.getElementById(dish.id), 1, {
          opacity: 0,
          y: 20,
          ease: Power2.easeOut,
          delay: idx * 0.1,
        })
      })
    }
  }

  scrollToDishIfNeeded() {
    const {
      location: { hash },
      facade,
    } = this.props
    if (!hash || !this.restaurantIsLoaded) {
      return
    }
    facade.utils.dom.scrollToHash(hash, {
      topOffset: -this.menuCategoriesOffset,
    })
  }

  renderDishesByMenuCategory() {
    const { restaurantStore, classes, facade } = this.props
    // const restaurantId = restaurantStore.restaurant.id
    if (facade.isPictureMode) {
      return Object.entries(restaurantStore.dishesByMenuCategory).map(
        ([menuCategory, dishes]) => (
          <Card
            key={menuCategory}
            title={menuCategory}
            slider={<DishComfySlider dishes={dishes} />}
          />
        )
      )
    }
    return (
      <div>
        <Scrollspy
          className={cx(classes.menuCategoryNav, 'no-scrollbar')}
          items={restaurantStore.menuCategories}
          currentClassName={classes.menuCategoryActive}
          componentTag="div"
          offset={-this.menuCategoriesOffset}
          onUpdate={this.onMenuCategoryScrollSpyUpdate}
        >
          {restaurantStore.menuCategories.map(menuCategory => (
            <AnchorLink
              offset={this.menuCategoriesOffset}
              href={`#${menuCategory}`}
              className={classes.menuCategoryLink}
              key={menuCategory}
            >
              {menuCategory}
            </AnchorLink>
          ))}
        </Scrollspy>
        <div>
          {Object.entries(restaurantStore.dishesByMenuCategory).map(
            ([menuCategory, dishes]) => (
              <div
                className={classes.dishes}
                key={menuCategory}
                id={menuCategory}
              >
                <div className={classes.menuCategoryDesc}>
                  <h2 className={classes.menuCategoryDescTitle}>
                    {menuCategory}
                  </h2>
                  <p className={classes.menuCategoryDescP}>
                    {dishes[0]['menuCategoryDescription']}
                  </p>
                </div>
                <div className={classes.dishesList}>
                  {dishes.map(this.renderDish)}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    )
  }

  renderDish = (dish, idx) => {
    const { classes, restaurantStore } = this.props
    return (
      <div
        className={classes.dishRoot}
        key={dish.name}
        id={dish.id}
        ref={el => {
          this.dishesEls[idx] = el
        }}
      >
        <div className={classes.dishTitleAndPrice}>
          <Typography className={classes.dishTitle}>{dish.name}</Typography>

          <Typography className={classes.dishPrice}>
            {dish.price} {ILS}
          </Typography>
        </div>
        <Typography className={classes.dishDescription}>
          {dish.description}
        </Typography>
        <DishAttributes
          attributesNames={restaurantStore.getDishAttributesNames(dish)}
        />
      </div>
    )
  }

  render() {
    const {
      classes,
      facade,
      restaurantStore: {
        state,
        errorMessage,
        restaurant: {
          id,
          googlePlacesId,
          gmReviewsAvg,
          gmReviews,
          phone,
          priceness,
          onlyVegan,
          kosher,
          address,
          latitude,
          longitude,
          logoUrl,
          name,
          open,
          openingHours,
          website,
          facebook,
          instagram,
          wolt,
          tenbis,
          mishloha,
          plweb,
          tabitorder,
        },
      },
    } = this.props
    console.log('[render] state', state)
    if (state === ERROR) {
      return (
        <div className={cx(classes.root, classes.loadingError)}>
          <Typography>{errorMessage}</Typography>
        </div>
      )
    }
    if (state === LOADING || facade.fetchAllDataState === LOADING) {
      return null
    }

    const { dishes } = this.props.restaurantStore.restaurant

    let metaTitle = `Plate | ${name} - תפריט`
    if (onlyVegan) {
      metaTitle = `Plate | ${name} - מסעדה טבעונית`
    }
    if (kosher) {
      metaTitle = `Plate | ${name} תפריט | מסעדה כשרה`
    }
    if (onlyVegan && kosher) {
      metaTitle = `Plate | ${name} - מסעדה טבעונית כשרה`
    }

    const restUrl = `https://plate.io/restaurants/${id}`

    const metaDesc = `למסעדת ${name} יש ${
      dishes.length
    } מנות. ${address}`

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{metaTitle}</title>
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDesc} />
          <meta property="og:url" content={restUrl} />
          <meta property="og:site_name" content="Plate.io" />
          <meta property="og:locale" content="he_IL" />
          <meta name="description" content={metaDesc} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={logoUrl} />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDesc} />
          <meta
            property="og:image"
            content={logoUrl.replace('https', 'http')}
          />
          <meta
            property="og:image:url"
            content={logoUrl.replace('https', 'http')}
          />
          <meta property="og:image:secure_url" content={logoUrl} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="400" />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "Restaurant",
                "name": "${metaTitle}",
                "url": "${restUrl}",
                "logo": "${logoUrl}",
                "telephone": "${phone}",
                "priceRange": "${'$'.repeat(priceness)}",
                "address": { "@type": "PostalAddress", "streetAddress": "${address}" },
                "geo": { "@type": "GeoCoordinates", "latitude": "${latitude}", "longitude": "${longitude}" }
              }
            `}
          </script>
        </Helmet>
        <Container className={classes.brand}>
          <Logo name={name} src={logoUrl} />
          <Typography variant="h1" className={classes.name}>
            {name}
          </Typography>
        </Container>
        <Container className={classes.details}>
          <ExternalLink
            className={cx(classes.reviews, classes.detail)}
            href={googlePlaceReivewLink(googlePlacesId)}
          >
            <Typography className={classes.gmReviewNumber}>
              {gmReviewsAvg}
            </Typography>
            <Rating value={gmReviewsAvg} />
            <Typography className={classes.gmReviews}>
              ({numberWithCommas(gmReviews)})
            </Typography>
          </ExternalLink>
          <WalkingTime
            restaurant={{ latitude, longitude }}
            className={classes.detail}
          />
          <Typography className={classes.detail}>
            <OpeningHours
              restaurantId={id}
              open={open}
              openingHours={openingHours}
            />
          </Typography>
          <Typography
            className={cx(classes.priceness, classes.detail)}
            variant="caption"
          >
            {ILS.repeat(priceness)}
          </Typography>
        </Container>
        <div className={classes.actionIcons}>
          <ExternalLink
            href={googleMapsPlaceLink({
              placeId: googlePlacesId,
              restName: name,
            })}
            className={classes.actionIcon}
          >
            <MapIcon />
            <Typography className={classes.actionIconLabel} variant="body2" />
          </ExternalLink>

          <a
            href="#"
            onClick={this.handleShareClick}
            className={classes.actionIcon}
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ShareIcon />
            <Typography className={classes.actionIconLabel} variant="body2" />
          </a>

          {phone && (
            <ExternalLink href={`tel:${phone}`} className={classes.actionIcon}>
              <PhoneIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
          {website && (
            <ExternalLink className={classes.actionIcon} href={website}>
              <LanguageIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
          {facebook && (
            <a
              href="#"
              onClick={this.handleFacebookClick}
              className={classes.actionIcon}
            >
              <FacebookIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </a>
          )}
          {instagram && (
            <ExternalLink
              className={classes.actionIcon}
              href={`https://www.instagram.com/${instagram}`}
            >
              <InstagramIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
          {tabitorder && (
            <ExternalLink
              className={classes.actionIcon}
              href={`https://www.tabitorder.com/?siteName=${tabitorder}`}
            >
              <DeliveryIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
          {plweb && (
            <ExternalLink
              className={classes.actionIcon}
              href={`https://order.plweb.online/wl/${plweb}`}
            >
              <DeliveryIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
          {mishloha && (
            <ExternalLink
              className={classes.actionIcon}
              href={`https://www.mishloha.co.il/wl/${mishloha}`}
            >
              <MishlohaIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
          {tenbis && (
            <ExternalLink
              className={classes.actionIcon}
              href={`https://www.10bis.co.il/next/restaurants/menu/delivery/${tenbis}`}
            >
              <TenbisIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
          {wolt && (
            <ExternalLink
              className={classes.actionIcon}
              href={`https://wolt.com/he/isr/tel-aviv/restaurant/${wolt}`}
            >
              <WoltIcon />
              <Typography className={classes.actionIconLabel} variant="body2" />
            </ExternalLink>
          )}
        </div>
        <div>{this.renderDishesByMenuCategory()}</div>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.headerHeight + theme.spacing(2),
    paddingBottom: theme.spacing(10),
  },
  loadingError: {
    paddingRight: theme.containerPadding,
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  detail: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child):before': {
      content: '"•"',
      fontSize: 14,
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  gmReviewNumber: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  gmReviews: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  actionIcons: {
    display: 'flex',
    justifyContent: 'space-around',
    opacity: 0.7,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  actionIcon: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  actionIconLabel: {
    direction: 'ltr',
    marginRight: theme.spacing(1),
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  menuCategoryLabelContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  menuCategoryNav: {
    background: 'rgb(51, 132, 173)',
    position: 'sticky',
    top: theme.headerHeight,
    listStyle: 'none',
    zIndex: 1000,
    overflowX: 'overlay',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 6px`,
    margin: 0,
    marginBottom: 0,
  },
  menuCategoryItem: {
    flex: 1,
  },
  menuCategoryLink: {
    height: MENU_CATEGORY_NAV_HEIGHT,
    whiteSpace: 'nowrap',
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    textDecoration: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    margin: `0 6px`,
    padding: `0 6px`,
  },
  menuCategoryActive: {
    margin: `0 0px !important`,
    padding: `0 12px !important`,
    borderRadius: 20,
    background: `#006699 !important`,
    height: `30px !important`,
  },
  menuCategoryDesc: {
    padding: `8px 12px`,
    background: `#93a2ab`,
    // borderTop: `1px solid white`,
  },
  menuCategoryDescTitle: {
    margin: 0,
    color: `white`,
    fontSize: '17px',
  },
  menuCategoryDescP: {
    margin: 0,
    color: `white`,
  },
  dishRoot: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom:theme.spacing(2),
    borderBottom: `1px solid #e0e0e0`,
    paddingRight: theme.containerPadding,
    paddingLeft: theme.containerPadding,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  dishTitleAndPrice: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dishTitle: {
    fontWeight: 'bold',
  },
  dishImgContainer: {
    height: DISH_IMG_DIMENSIONS,
    position: 'relative',
  },
  dishImg: {
    width: DISH_IMG_DIMENSIONS,
    height: DISH_IMG_DIMENSIONS,
    objectFit: 'cover',
  },
  dishBottomBar: {
    background: 'rgba(0,0,0,0.7)',
    width: DISH_IMG_DIMENSIONS,
    height: theme.spacing(4),
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    bottom: 0,
    paddingRight: theme.containerPadding,
    paddingLeft: theme.containerPadding,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dishBottomBarText: {
    color: theme.palette.primary.contrastText,
  },
  dishDescription: {
    marginBottom: theme.spacing(1),
  },
})

export default withTheme(withStyles(styles)(withRouter(RestaurantPage)))
