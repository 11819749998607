import { rIC } from 'idlize/idle-callback-polyfills.mjs'

import dishTypesData from '../data/dishTypesData'
import history from '../utils/history'
import {
  isRestaurantListRoute,
  isHomeRoute,
  isDishRoute,
  isRestaurantRoute,
} from '../utils/routing'

class BootingMeta {
  constructor({ facade, stores, services, managers, helpers, utils }) {
    this.stores = stores
    this.services = services
    this.managers = managers
    this.helpers = helpers
    this.utils = utils
    this.facade = facade
  }

  boot = () => {
    this.reportStart()
    this.stores.locationStore.loadGooglePlacesService()
    this.facade.fetchAllData()
    return Promise.resolve()
      .then(this.stores.locationStore.getLocationIfGrantedPermission)
      .then(this.handleLocation)
      .then(this.fetchResource)
      .then(this.reportSuccess)
      .catch(this.reportError)
  }

  preload = () => {
    rIC(() => {
      this.preloadDishTypesPressedIcons()
    })
  }

  preloadDishTypesPressedIcons = () => {
    dishTypesData
      .map(dishType => dishType.imgSrcActive)
      .forEach(path => {
        const img = new Image()
        img.src = path
      })
  }

  handleLocation = locationIsSet => {
    this.facade.hydrateStatesFromQueryParams()
    if (
      (isRestaurantListRoute(this.pathname) || isHomeRoute(this.pathname)) &&
      locationIsSet
    ) {
      this.facade.updateAddresBarQueryParams()
    }
  }

  fetchResource = () => {
    if (isRestaurantListRoute(this.pathname) || isHomeRoute(this.pathname)) {
      this.facade.fetchRestaurantList()
    } else if (isDishRoute(this.pathname)) {
      this.facade.fetchDishFromUrl()
    } else if (isRestaurantRoute(this.pathname)) {
      this.facade.fetchRestaurantFromUrl()
    }
  }

  get pathname() {
    return history.location.pathname
  }

  /*
   * Logging
   */

  reportStart = () => global.console.debug('[Boot] -> Start')

  reportSuccess = () => global.console.debug('[Boot] -> Success')

  reportError = error => global.console.error('[Boot] -> Error ->', error)
}

export default BootingMeta
