/* eslint-disable react/forbid-foreign-prop-types */
import PropTypes from 'prop-types'

// eslint-disable-next-line import/prefer-default-export
export const reportMissingProps = (Component, componentInstance) => {
  if (!Component.propTypes) {
    global.console.warn('[Report missing props] -> propTypes empty -> bailing')
    return
  }
  // see https://github.com/facebook/react/issues/16069
  PropTypes.checkPropTypes(
    Component.propTypes,
    componentInstance.props,
    'prop',
    `${Component.name} -> ${identifyingProps(componentInstance)}`
  )
}

function identifyingProps(componentInstance) {
  const {
    props: { id, name, title },
  } = componentInstance
  return id || name || title
}
