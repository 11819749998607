import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import withStyles from '@material-ui/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import cx from 'classnames'
import throttle from 'lodash/throttle'

import { SEARCH_PLACEHOLDER } from '../constants/constants'
import {
  searchRoute,
  isRestaurantListRoute,
  isRestaurantRoute,
  isDishRoute,
  routeBack,
} from '../utils/routing'

import Link from './Link'

@inject('facade', 'locationStore', 'restaurantStore')
@observer
class Header extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    locationStore: PropTypes.object.isRequired,
    restaurantStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  @observable shouldShowScrolledHeader = false

  onScroll = throttle(() => {
    this.handleRestaurantScrolledHeaderDisplay()
  }, 50)

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleRestaurantScrolledHeaderDisplay()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  get searchText() {
    const {
      facade,
      locationStore: { address },
    } = this.props
    if (facade.topBarSearchText) {
      return facade.topBarSearchText
    }
    if (!facade.topBarSearchText && address) {
      return ''
    }
    return SEARCH_PLACEHOLDER
  }

  get isSearchEmpty() {
    const {
      facade,
      locationStore: { address },
    } = this.props
    return !facade.topBarSearchText && !address
  }

  handleRestaurantScrolledHeaderDisplay() {
    const { location } = this.props
    if (!isRestaurantRoute(location.pathname)) {
      return
    }
    if (!this.shouldShowScrolledHeader && window.scrollY >= 50) {
      this.shouldShowScrolledHeader = true
    } else if (this.shouldShowScrolledHeader && window.scrollY < 50) {
      this.shouldShowScrolledHeader = false
    }
  }

  renderHeaderWithSearch() {
    const { classes, locationStore } = this.props
    return (
      <div className={classes.headerWithSearchRoot}>
        <AppBar
          position="fixed"
          classes={{ root: classes.headerWithSearchAppBar }}
        >
          <Toolbar classes={{ root: classes.headerWithSearchToolbar }}>
            <Link to={searchRoute()} className={classes.headerWithSearchSearch}>
              <div className={classes.headerWithSearchSearchIcon}>
                <SearchIcon />
              </div>
              <Typography
                className={cx(classes.headerWithSearchSearchQuery, {
                  [classes.headerWithPlaceholderText]: this.isSearchEmpty,
                })}
              >
                {this.searchText}
              </Typography>
              <Typography className={classes.headerWithSearchLocation}>
                {locationStore.address}
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>
      </div>
    )
  }

  renderHeader() {
    const {
      classes,
      location: { pathname },
    } = this.props
    return (
      <div>
        <div
          className={cx(classes.header, {
            [classes.isHeaderHidden]: isDishRoute(pathname),
          })}
        >
          {this.renderHeaderRestaurantTitle()}
        </div>
        <ArrowForwardIcon
          className={classes.backIcon}
          onClick={() => routeBack()}
          fontSize="small"
        />
      </div>
    )
  }

  renderHeaderRestaurantTitle() {
    const {
      restaurantStore,
      classes,
      location: { pathname },
    } = this.props
    if (!isRestaurantRoute(pathname)) {
      return null
    }
    return (
      <Typography
        variant="h6"
        className={cx(classes.restaurantHeaderHeadline, {
          [classes.restaurantHide]: !this.shouldShowScrolledHeader,
        })}
      >
        {restaurantStore.restaurant.name}
      </Typography>
    )
  }

  render() {
    const {
      location: { pathname },
    } = this.props
    if (isRestaurantListRoute(pathname)) {
      return this.renderHeaderWithSearch()
    }

    if (isRestaurantRoute(pathname) || isDishRoute(pathname)) {
      return this.renderHeader()
    }

    return null
  }
}

const styles = theme => ({
  header: {
    display: 'flex',
    height: theme.headerHeight,
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    flex: 1,
    zIndex: 100,
    background: theme.palette.primary.main,
    transition: '0.4s ease',
    paddingRight: theme.headerHeight,
  },
  isHeaderHidden: {
    top: -theme.headerHeight,
  },
  backIcon: {
    zIndex: 101,
    position: 'fixed',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    height: theme.headerHeight,
    width: theme.headerHeight,
    padding: theme.containerPadding,
  },
  headerWithSearchRoot: {
    width: '100%',
    zIndex: 100,
  },
  headerWithSearchAppBar: {
    boxShadow: 'none',
  },
  headerWithSearchToolbar: {
    height: theme.headerWithSearchHeight,
  },
  headerWithSearchSearch: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    paddingTop: 6,
    paddingBottom: 6,
    width: '100%',
    overflow: 'hidden',
    '&:focus': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  headerWithSearchSearchIcon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  headerWithSearchSearchQuery: {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1),
    fontSize: 16,
  },
  headerWithPlaceholderText: {
    color: theme.palette.text.secondary,
  },
  headerWithSearchLocation: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    fontSize: 16,
    // TODO [AdGo] 11/05/19 - calc max-width according to main search
  },
  restaurantHeaderHeadline: {
    opacity: 1,
    transition: '0.2s opacity',
    color: theme.palette.primary.contrastText,
  },
  restaurantHide: {
    opacity: 0,
    pointerEvents: 'none',
  },
})

export default withStyles(styles)(withRouter(Header))
