import {
  extendObservable,
  action,
  observable,
  runInAction,
  transaction,
} from 'mobx'
import remotedev from 'mobx-remotedev'

import {
  routeToSearch,
  routeToDish,
  routeToRestaurant,
  routeToRestaurants,
  replaceRouteToRestaurants,
  routeToDishInRestaurant,
} from '../utils/routing'
import {
  DISH,
  RESTAURANT,
  DISH_TYPE,
  LOADING,
  LOADED,
  ERROR,
  INITIAL,
} from '../constants/constants'

const DOWN = 'down'
const UP = 'up'
const NONE = 'none'

class Facade {
  @observable restaurantListVerticalScrollDirection = NONE

  @observable restaurantListVerticalScrollPosition = 0

  @observable fetchAllDataState = INITIAL

  constructor({
    stores,
    managers,
    helpers,
    utils,
    serializerService,
    apiService,
    cacheService,
    requestService,
    history,
  }) {
    this.stores = stores
    this.managers = managers
    this.helpers = helpers
    this.utils = utils
    this.filtersStore = stores.filtersStore
    this.dishTypeStore = stores.dishTypeStore
    this.restaurantListStore = stores.restaurantListStore
    this.dishStore = stores.dishStore
    this.searchStore = stores.searchStore
    this.locationStore = stores.locationStore
    this.restaurantStore = stores.restaurantStore
    this.serializerService = serializerService
    this.apiService = apiService
    this.cacheService = cacheService
    this.requestService = requestService
    this.history = history
    extendObservable(this, {
      isPictureMode:
        this.managers.localStorageManager.getIsPictureMode() || false,
    })
  }

  @action.bound
  fetchAllData = () => {
    this.fetchAllDataPromise = new Promise(async (resolve, reject) => {
      this.reportFetchAllDataStart()
      this.fetchAllDataState = LOADING
      const { data, errorMessage } = await this.requestService.fetchAllData()
      if (errorMessage) {
        this.reportFetchAllDataError(errorMessage)
        reject(errorMessage)
        this.fetchAllDataState = ERROR
      }
      this.reportFetchAllDataSuccess(data)
      this.cacheService.hydrate({
        restaurants: data.restaurants,
        categories: data.categories,
      })
      resolve({ data })
      this.fetchAllDataState = LOADED
    })
    this.requestService.setFetchAllDataPromise(this.fetchAllDataPromise)
  }

  @action
  hydrateStatesFromQueryParams() {
    const {
      filters,
      dishCatId,
      searchQuery,
      address,
      latitude,
      longitude,
    } = this.serializerService.deserializeQueryParams()
    this.filtersStore.hydrateState(filters)
    this.dishTypeStore.hydrateState(dishCatId)
    this.searchStore.hydrateState(searchQuery || this.dishTypeStore.activeName)
    this.locationStore.hydrateState({ address, latitude, longitude })
  }

  @action
  updateAddresBarQueryParamsAndRoute() {
    routeToRestaurants({ queryParams: this.serializedqueryParams() })
  }

  @action
  updateAddresBarQueryParams() {
    replaceRouteToRestaurants({ queryParams: this.serializedqueryParams() })
  }

  @action
  selectFromSearchSuggestions(item) {
    const { type, name, id, restaurantId } = item.value
    this.filtersStore.resetFilters()
    this.dishTypeStore.resetType()
    if (type === RESTAURANT) {
      this.routeToRestaurant({ id })
    } else if (type === DISH) {
      this.routeToDish({ id, restaurantId })
    } else if (type === DISH_TYPE) {
      this.searchStore.setQuery(name)
      this.dishTypeStore.setDishType(id)
      this.routeToRestaurantList()
    }
  }

  @action
  searchButtonClick() {
    this.search()
  }

  @action
  onSearchEnterClick() {
    this.search()
  }

  @action
  search() {
    this.dishTypeStore.syncFromName(this.searchStore.searchQuery)
    this.routeToRestaurantList()
  }

  @action
  toggleSimpleFilter(filterNameKey) {
    this.filtersStore.toggleSimpleFilter(filterNameKey)
    setTimeout(() => {
      runInAction(() => {
        this.fetchRestaurantList()
        this.updateAddresBarQueryParams()
      })
    }, 0)
  }

  @action
  toggleDishType(id) {
    transaction(() => {
      this.dishTypeStore.toggleDishType(id)
      if (this.dishTypeStore.isActive) {
        this.searchStore.setQuery(this.dishTypeStore.getDishName(id))
      } else {
        this.searchStore.resetQuery()
      }
    })
    // prioritize rendering dish type changes over restaurants and url changes
    setTimeout(() => {
      runInAction(() => {
        this.fetchRestaurantList()
        // prioritize rendering restaurant changes over url changes
        setTimeout(() => {
          runInAction(() => {
            this.updateAddresBarQueryParams()
          })
        }, 0)
      })
    }, 0)
  }

  @action.bound
  fetchRestaurantList = () =>
    this.restaurantListStore.fetchRestaurantList({
      states: this.getStatesObject(),
    })

  @action.bound
  fetchSuggestions() {
    return this.searchStore.fetchSuggestions(
      this.getStatesObjectWithoutCoordinates()
    )
  }

  get topBarSearchText() {
    if (this.dishTypeStore.isActive) {
      return `מנות מסוג ${this.searchStore.searchQuery}`
    }
    return this.searchStore.searchQuery
  }

  serializedqueryParams() {
    return this.serializerService.serializeState({
      states: this.getStatesObject(),
    })
  }

  getStatesObject = () => ({
    filters: {
      ...this.filtersStore.filters,
      dishTypeId: this.dishTypeStore.id,
      searchQuery: this.searchStore.searchQuery,
    },
    location: {
      address: this.locationStore.address,
      latitude: this.locationStore.latitude,
      longitude: this.locationStore.longitude,
    },
  })

  getStatesObjectWithoutCoordinates = () => ({
    filters: {
      ...this.filtersStore.filters,
      dishTypeId: this.dishTypeStore.id,
      searchQuery: this.searchStore.searchQuery,
    },
    location: {
      address: this.locationStore.address,
    },
  })

  /*
   * Routing methods
   */

  @action
  routeToDish({ id, restaurantId }) {
    if (!this.dishStore.doesActiveMatchId(id)) {
      this.dishStore.fetchDish({ id, restaurantId })
    }
    routeToDish({ id })
    this.utils.dom.scrollTop()
  }

  @action
  routeToDishInRestaurant = ({ dishId, restaurantId }) => {
    if (!this.restaurantStore.doesActiveMatchId(restaurantId)) {
      this.restaurantStore.fetchRestaurant({
        id: restaurantId,
        states: this.getStatesObject(),
      })
    }
    routeToDishInRestaurant({ restaurantId, dishId })
  }

  @action
  fetchDishFromUrl = () => this.dishStore.fetchDishFromUrl()

  @action
  fetchRestaurantFromUrl = () => this.restaurantStore.fetchRestaurantFromUrl()

  @action routeToRestaurant({ id }) {
    if (!this.restaurantStore.doesActiveMatchId(id)) {
      this.restaurantStore.fetchRestaurant({
        id,
        states: this.getStatesObject(),
      })
    }
    this.utils.dom.scrollTop()
    routeToRestaurant({ id })
  }

  @action
  routeToRestaurantListAndReset() {
    this.filtersStore.resetFilters()
    this.dishTypeStore.resetType()
    this.searchStore.resetQuery()
    this.routeToRestaurantList()
  }

  @action
  routeToRestaurantList() {
    this.utils.dom.scrollTop()
    this.locationStore.geoCoderPromise.then(() => this.fetchRestaurantList())
    setTimeout(() => {
      runInAction(() => {
        this.updateAddresBarQueryParamsAndRoute()
      })
    }, 100)
  }

  @action
  routeToSearchAndResetQuery() {
    this.searchStore.resetQuery()
    routeToSearch()
  }

  /*
   * Restaurant list vertical scroll
   */

  setRestaurantListVerticalScrollPosition = position => {
    this.restaurantListVerticalScrollPosition = position
  }

  setRestaurantListVerticalScrollDirectionDown() {
    this.restaurantListVerticalScrollDirection = DOWN
  }

  setRestaurantListVerticalScrollDirectionUp() {
    this.restaurantListVerticalScrollDirection = UP
  }

  setRestaurantListVerticalScrollDirectionNone() {
    this.restaurantListVerticalScrollDirection = NONE
  }

  get restaurantListVerticalScrollDirectionIsDown() {
    return this.restaurantListVerticalScrollDirection === DOWN
  }

  /*
   * Location store
   */
  get gotUserLocation() {
    return this.locationStore.gotUserLocation
  }

  get latitude() {
    return this.locationStore.latitude
  }

  get longitude() {
    return this.locationStore.longitude
  }

  get locationIsPresent() {
    return this.locationStore.locationIsPresent
  }

  /*
   * Misc
   */

  @action
  calcWalkingDistanceToRestaurant = ({ restaurant, latitude, longitude }) =>
    this.helpers.distance.calcWalkingDistanceToRestaurant({
      restaurant,
      latitude,
      longitude,
    })

  /*
   * Logging & Misc
   */

  togglePictureMode = () => {
    global.console.debug('[Secret] You cracked it!')
    const nextValue = !this.isPictureMode
    this.isPictureMode = nextValue
    this.managers.localStorageManager.setIsPictureMode(nextValue)
    this.fetchRestaurantList()
  }

  reportFetchAllDataStart = () => {
    global.console.debug('[Fetching all data] -> Start')
  }

  reportFetchAllDataError = errorMessage =>
    global.console.error('[Fetching all data] -> error msg ->', errorMessage)

  reportFetchAllDataSuccess = data =>
    global.console.debug('[Fetching all data] -> success -> data ->', data)

  reportPrematureAllDataPromiseResolved = (...args) =>
    global.console.debug(
      '[Fetch all data promise] Prematurly resolved placdeholder promise -> args',
      args
    )

  reportPrematureAllDataPromiseRejected = (...args) =>
    global.console.debug(
      '[Fetch all data promise] Prematurly rejected placdeholder promise -> args',
      args
    )
}

export default remotedev(Facade)
