import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import withStyles from '@material-ui/styles/withStyles'
import cx from 'classnames'

import { SPICY } from '../constants/constants'

@inject('facade', 'filtersStore')
@observer
class Filter extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    isOn: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }

  secretPressTimeout

  // breaks mobx-react observer, filled an issue: https://github.com/mobxjs/mobx/issues/2028
  // shouldComponentUpdate(nextProps) {
  //   return nextProps.isOn !== this.props.isOn
  // }

  handleButtonPress = () => {
    const { facade, value } = this.props
    if (value !== SPICY) {
      return
    }
    global.console.debug('[Secret] Press start ...')
    this.secretPressTimeout = setTimeout(() => facade.togglePictureMode(), 2000)
  }

  handleButtonRelease = () => {
    const { value } = this.props
    if (value !== SPICY) {
      return
    }
    clearTimeout(this.secretPressTimeout)
  }

  toggleSimpleFilter = () =>
    this.props.facade.toggleSimpleFilter(this.props.value)

  render() {
    const { classes, label, isOn } = this.props
    return (
      <li className={classes.itemRoot}>
        <Chip
          label={label}
          clickable
          onTouchStart={this.handleButtonPress}
          onTouchEnd={this.handleButtonRelease}
          onMouseDown={this.handleButtonPress}
          onMouseUp={this.handleButtonRelease}
          onMouseLeave={this.handleButtonRelease}
          onClick={this.toggleSimpleFilter}
          className={cx(classes.item, {
            [classes.isActive]: isOn,
          })}
        />
      </li>
    )
  }
}

const styles = theme => ({
  itemRoot: {
    margin: theme.spacing(1 / 2),
  },
  item: {
    height: theme.filter.height,
    fontSize: theme.filter.fontSize,
    borderRadius: theme.shape.borderRadius,
    background: '#f3f3f3',
    border: '1px solid #eaeaea',
  },
  isActive: {
    background: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
  },
})

export default withStyles(styles)(Filter)
