import { action } from 'mobx'

class RequestService {
  fetchAllDataPromise = this.fetchAllDataPromisePlaceholder

  constructor({ apiService, cacheService }) {
    this.apiService = apiService
    this.cacheService = cacheService
  }

  @action
  setFetchAllDataPromise = fetchAllDataPromise => {
    this.fetchAllDataPromise = fetchAllDataPromise
  }

  fetchRestaurantList = async (...args) => {
    await this.fetchAllDataPromise
    return this.cacheService.isActive
      ? this.cacheService.getRestaurantList(...args)
      : this.apiService.fetchRestaurantList(...args)
  }

  fetchRestaurant = async (...args) => {
    await this.fetchAllDataPromise
    return this.cacheService.isActive
      ? Promise.resolve(this.cacheService.getRestaurant(...args))
      : this.apiService.fetchRestaurant(...args)
  }

  fetchDish = async (...args) => {
    await this.fetchAllDataPromise
    return this.cacheService.isActive
      ? Promise.resolve(this.cacheService.getDish(...args))
      : this.apiService.fetchDish(...args)
  }

  fetchAllData = (...args) => this.apiService.fetchAllData(...args)
}

export default RequestService
