import { action } from 'mobx'
import orderByDistance from 'geolib/es/orderByDistance'
import sumBy from 'lodash/sumBy'

import { isRestaurantOpenNow } from '../utils/openingHours.util'
import { DISH_ATTRIBUTES, RESTAURANT_ATTRIBUTES } from '../constants/constants'
import { pipe } from '../utils/utils'

class FilterService {
  params = { filters: {}, location: {} }

  /* ************************************* /
  * Interface
  /************************************** */

  @action
  getFilteredRestaurantsAndDishes({ params, restaurants }) {
    this.params = this.generateParams(params)
    this.reportFilteringStart()
    const filteredResultsAndDishes = pipe(this.activePipedFunctions)(
      restaurants
    )
    this.reportFilteringEnd(filteredResultsAndDishes)
    return filteredResultsAndDishes
  }

  /* ************************************* /
  * Get all piped methods
  /************************************** */

  get activePipedFunctions() {
    return [
      ...this.activePipedRestaurantsFilterFunctions,
      ...this.activePipedRestaurantsDishesFilterFunctions,
      ...this.activePipedSortingFunctions,
    ]
  }

  get activePipedRestaurantsFilterFunctions() {
    const restaurantFilterFunctions = []
    if (this.shouldFilterRestaurantAttributes()) {
      restaurantFilterFunctions.push(this.matchesRestaurantAttributes)
    }
    if (this.shouldFilterIsOpen()) {
      restaurantFilterFunctions.push(this.matchesIsOpen)
    }
    return restaurantFilterFunctions
  }

  get activePipedRestaurantsDishesFilterFunctions() {
    const dishesFilterFunctions = []
    if (this.shouldFilterDishTypes()) {
      dishesFilterFunctions.push(this.matchesDishType)
    }
    if (this.shouldFilterDishAttributes()) {
      dishesFilterFunctions.push(this.matchesDishAttributes)
    }
    if (this.shouldFilterSearchQuery()) {
      dishesFilterFunctions.push(this.matchesSearchQuery)
    }
    return dishesFilterFunctions
  }

  get activePipedSortingFunctions() {
    const sortFunctions = []
    if (this.shouldSortByLocation()) {
      sortFunctions.push(this.sortByLocation)
    }
    return sortFunctions
  }

  /* ************************************* /
  * Filtering methods by restaurant
  /************************************** */

  @action
  matchesIsOpen = restaurants =>
    restaurants.filter(restaurant =>
      isRestaurantOpenNow({ restaurant, date: this.params.date })
    )

  @action
  matchesRestaurantAttributes = restaurants =>
    restaurants.filter(restaurant =>
      this.restaurantAttributes.every(attribute => restaurant[attribute])
    )

  /* ************************************* /
  * Filtering methods by restaurant's dishes
  /************************************** */

  @action
  matchesDishType = restaurants =>
    restaurants.reduce((accRestaurants, restaurant) => {
      const dishes = restaurant.dishes.filter(this.dishTypeFilter)
      if (dishes.length) {
        accRestaurants.push({ ...restaurant, dishes })
      }
      return accRestaurants
    }, [])

  @action
  dishTypeFilter = dish => dish.dishTypeId === this.params.filters.dishTypeId

  @action
  matchesDishAttributes = restaurants =>
    restaurants.reduce((accRestaurants, restaurant) => {
      const dishes = restaurant.dishes.filter(this.dishAttributesFilter)
      if (dishes.length) {
        accRestaurants.push(restaurant)
      }
      return accRestaurants
    }, [])

  @action
  dishAttributesFilter = dish =>
    this.dishAttributes.every(attribute => dish[attribute])

  /* ************************************* /
  * Filtering methods by restaurant and dishes
  /************************************** */

  @action
  matchesSearchQuery = restaurants =>
    restaurants.reduce((accRestaurants, restaurant) => {
      if (this.restaurantMatchesSearchQuery(restaurant)) {
        return accRestaurants.concat(restaurant)
      }
      const dishes = restaurant.dishes.filter(this.dishSearchQueryFilter)
      if (dishes.length) {
        accRestaurants.push(restaurant)
      }
      return accRestaurants
    }, [])

  restaurantMatchesSearchQuery = restaurant =>
    restaurant.name.includes(this.params.filters.searchQuery)

  dishSearchQueryFilter = dish => {
    const query = this.params.filters.searchQuery
    if (dish.name && dish.description) {
      return dish.name.includes(query) || dish.description.includes(query)
    }
    if (dish.name) {
      return dish.name.includes(query)
    }
    if (dish.description) {
      return dish.description.includes(query)
    }
    return false
  }

  /* ************************************* /
  * Sorting methods by restaurant
  /************************************** */

  @action
  sortByLocation = restaurants => {
    const { latitude, longitude } = this.params.location
    return orderByDistance({ latitude, longitude }, restaurants)
  }

  /* ************************************* /
  * Checks for which filters should be used
  /************************************** */
  // todo compare performance with using @computed getters

  shouldFilterIsOpen = () => this.params.filters.openNow

  shouldFilterRestaurantAttributes = () => !!this.restaurantAttributes.length

  shouldFilterDishTypes = () => !!this.params.filters.dishTypeId

  shouldFilterDishAttributes = () => !!this.dishAttributes.length

  shouldFilterSearchQuery = () => !!this.params.filters.searchQuery

  /* ************************************* /
  * Check which sorting should be used
  /************************************** */

  shouldSortByLocation = () => {
    const { latitude, longitude } = this.params.location
    return !!(latitude && longitude)
  }

  /* ************************************* /
  * helpers
  /************************************** */

  generateParams = params => {
    return {
      ...this.handleDishTypeAndSearchQueryParams(params),
      date: new Date(),
    }
  }

  handleDishTypeAndSearchQueryParams = params => {
    if (!params.filters.dishTypeId) {
      return params
    }
    return { ...params, filters: { ...params.filters, searchQuery: '' } }
  }

  get restaurantAttributes() {
    return RESTAURANT_ATTRIBUTES.filter(
      restaurantAttribute => this.params.filters[restaurantAttribute]
    )
  }

  get dishAttributes() {
    return DISH_ATTRIBUTES.filter(
      dishAttribute => this.params.filters[dishAttribute]
    )
  }

  /*
   * Logging
   */

  reportFilteringStart() {
    global.console.debug(
      '[Filter Service] Getting results -> Params',
      this.params
    )
  }

  reportFilteringEnd(filteredResultsAndDishes) {
    const restaurantsCount = filteredResultsAndDishes.length
    const dishCount = sumBy(
      filteredResultsAndDishes,
      restaurant => restaurant.dishes.length
    )
    global.console.debug(
      `[Filter Service] -> Results -> ${restaurantsCount} restaurants and ${dishCount} dishes`,
      filteredResultsAndDishes
    )
  }
}

export default FilterService
