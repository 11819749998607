import Facade from './Facade'

const createFacade = ({
  utils,
  managers,
  helpers,
  stores,
  serializerService,
  apiService,
  cacheService,
  requestService,
  history,
}) => {
  const facade = new Facade({
    utils,
    managers,
    helpers,
    stores,
    serializerService,
    apiService,
    cacheService,
    requestService,
    history,
  })
  return facade
}

export default createFacade
