import { observable, action } from 'mobx'

import { DISH, RESTAURANT } from '../constants/constants'

const SEARCH_RESULTS_ERROR_TEXT = 'ארעה שגיאה, נסה/י חיפוש אחר'

export default class SearchStore {
  @observable searchQuery = ''

  constructor({ apiService }) {
    this.apiService = apiService
  }

  @action
  setQuery(searchQuery) {
    this.searchQuery = searchQuery
  }

  @action.bound
  fetchSuggestions = states =>
    new Promise(async resolve => {
      this.reportFetchSuggestions(states)
      const { data, errorMessage } = await this.apiService.fetchSuggestions({
        states,
      })
      if (errorMessage) {
        this.reportFetchSuggestionsError({ states, errorMessage })
        return resolve([{ label: SEARCH_RESULTS_ERROR_TEXT, value: '' }])
      }
      this.reportFetchSuggestionsSuccess(data)
      resolve(this.formatSuggestionsFromServer(data))
    })

  formatSuggestionsFromServer({ dishes, restaurants, categories }) {
    return [
      ...categories.map(cat => ({
        label: cat.name,
        value: cat,
      })),
      ...restaurants.map(restaurant => ({
        label: restaurant.name,
        value: { ...restaurant, type: RESTAURANT },
      })),
      ...dishes.map(dish => ({
        label: dish.name,
        value: { ...dish, type: DISH },
      })),
    ]
  }

  @action
  hydrateState(searchQuery) {
    this.searchQuery = searchQuery
  }

  @action
  resetQuery() {
    this.searchQuery = ''
  }

  /*
   * Logging
   */

  reportFetchSuggestions = states =>
    global.console.debug('[Fetch suggestions] -> States ->', states)

  reportFetchSuggestionsSuccess = data =>
    global.console.debug('[Fetch suggestions] -> Success ->', data)

  reportFetchSuggestionsError = ({ states, errorMessage }) =>
    global.console.debug(
      '[Fetch suggestions] -> Error ->',
      errorMessage,
      'States -> ',
      states
    )
}
