/* eslint-disable react/prop-types */

import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import withTheme from '@material-ui/styles/withTheme'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import PlaceIcon from '@material-ui/icons/Place'
import { inject, observer } from 'mobx-react'
import AsyncSelect from 'react-select/async'
import cx from 'classnames'

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, inputIconClassName, children, ...props }) {
  return (
    <div ref={inputRef} {...props}>
      <PlaceIcon className={inputIconClassName} />
      {children}
    </div>
  )
}

function Control(props) {
  const { classes } = props.selectProps
  return (
    <TextField
      fullWidth
      className={classes.textFieldRoot}
      InputProps={{
        disableUnderline: true,
        inputComponent,
        inputProps: {
          className: classes.input,
          inputIconClassName: classes.inputIcon,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}

function Option(props) {
  const {
    children,
    selectProps: { locationStore, classes },
  } = props
  const text =
    children === locationStore.CURRENT_LOCATION_LABEL ? (
      <span className={classes.currentLocation}>{children}</span>
    ) : (
      children
    )
  return (
    <MenuItem buttonRef={props.innerRef} component="div" {...props.innerProps}>
      {text}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function Value(props) {
  return (
    <Typography
      className={props.selectProps.classes.value}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  const {
    selectProps: { classes, locationStore },
  } = props
  return (
    <div
      className={cx(classes.valueContainer, {
        [classes.currentLocation]:
          locationStore.CURRENT_LOCATION_LABEL === locationStore.address,
      })}
    >
      {props.children}
    </div>
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const reactSelectComponents = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  Value,
  ValueContainer,
  IndicatorsContainer: () => null,
}

@inject('locationStore')
@observer
class AddressAutoComplete extends React.Component {
  static propTypes = {
    locationStore: PropTypes.object.isRequired,
  }

  get selectStyles() {
    const { locationStore, theme } = this.props
    return {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
          fontWeight: locationStore.isCurrentLocationSelected
            ? 'bold'
            : 'inherit',
          color: locationStore.isCurrentLocationSelected
            ? '#006699 !important'
            : 'inherit',
          opacity: '1 !important', // HACK: AsyncSelect applies opacity: 1 on select
          width: 'calc(100vw - 110px) !important',
        },
      }),
    }
  }

  onFocus = () => {
    const { locationStore } = this.props
    if (locationStore.isCurrentLocationSelected) {
      locationStore.resetAddress()
    }
  }

  onSelect = addressObj => {
    const { locationStore, onSelect } = this.props
    locationStore.onSelectAddress(addressObj)
    onSelect()
  }

  onInputChange = (value, { action }) => {
    if (action !== 'input-change') {
      return
    }
    const { locationStore } = this.props
    locationStore.onAddressInputChange(value)
  }

  render() {
    const { classes, locationStore } = this.props
    return (
      <div>
        <AsyncSelect
          openMenuOnFocus
          onFocus={this.onFocus}
          styles={this.selectStyles}
          value={locationStore.address}
          inputValue={locationStore.addressDisplay}
          noOptionsMessage={() => locationStore.NO_OPTIONS_LABEL}
          onChange={this.onSelect}
          onInputChange={this.onInputChange}
          classes={classes}
          components={reactSelectComponents}
          cacheOptions
          defaultOptions
          loadingMessage={() => 'טוען...'}
          locationStore={locationStore}
          loadOptions={locationStore.fetchAddressSuggestions}
          placeholder="מיקום"
        />
      </div>
    )
  }
}

const styles = theme => ({
  textFieldRoot: {
    paddingRight: theme.containerPadding,
  },
  input: {
    display: 'flex',
    padding: `0 0 0 ${theme.containerPadding}px`,
    height: theme.searchAutocompleteHeight,
    alignItems: 'center',
  },
  inputIcon: {
    fontSize: theme.searchAutocompleteIconDimensions,
    marginLeft: theme.searchAutocompleteIconMargin,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  value: {
    fontSize: 15,
  },
  placeholder: {
    position: 'absolute',
    right: theme.searchAutocompletePlaceholderOffset,
    fontSize: 15,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: -10,
    right: 0,
    top: theme.searchAutocompleteHeight - 6,
    width: '100vw',
  },
  currentLocation: {
    fontWeight: 'bold',
    color: '#006699',
  },
  divider: {
    height: theme.spacing(2),
  },
})

export default withStyles(styles)(withTheme(AddressAutoComplete))
