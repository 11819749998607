import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import Modal from '@material-ui/core/Modal'
import withStyles from '@material-ui/styles/withStyles'

@inject('restaurantListStore')
@observer
class RestaurantListEndOfResultsModal extends Component {
  static propTypes = {
    restaurantListStore: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  }

  render() {
    const { restaurantListStore, classes } = this.props
    return (
      <Modal
        open={restaurantListStore.endOfResultsModalIsOn}
        className={classes.root}
        onBackdropClick={restaurantListStore.toggleEndOfResultsModal}
      >
        <iframe
          title="זה הסוף..."
          width={window.innerWidth}
          height="400"
          src="https://www.youtube-nocookie.com/embed/EkFCJyqd0As?start=58&autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>
    )
  }
}

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default withStyles(styles)(RestaurantListEndOfResultsModal)
