export default [
  {
    id: 9,
    imgSrc: '/icons/sushi.png',
    imgSrcActive: '/icons/sushi-pressed.png',
    name: 'סושי',
  },
  {
    id: 27,
    imgSrc: '/icons/rice.png',
    imgSrcActive: '/icons/rice-pressed.png',
    name: 'אורז',
  },
  {
    id: 37,
    imgSrc: '/icons/noodle.png',
    imgSrcActive: '/icons/noodle-pressed.png',
    name: 'נודלס',
  },
  {
    id: 10,
    imgSrc: '/icons/salad.png',
    imgSrcActive: '/icons/salad-pressed.png',
    name: 'סלט',
  },
  {
    id: 2,
    imgSrc: '/icons/burger.png',
    imgSrcActive: '/icons/burger-pressed.png',
    name: 'בורגר',
  },
  {
    id: 5,
    imgSrc: '/icons/shawarma.png',
    imgSrcActive: '/icons/shawarma-pressed.png',
    name: 'שווארמה',
  },
  {
    id: 30,
    imgSrc: '/icons/fries.png',
    imgSrcActive: '/icons/fries-pressed.png',
    name: 'טוגנים',
  },
  {
    id: 1,
    imgSrc: '/icons/pizza.png',
    imgSrcActive: '/icons/pizza-pressed.png',
    name: 'פיצה',
  },
]
