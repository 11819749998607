import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import { reportMissingProps } from '../utils/react.util'
import { numberWithCommas } from '../utils/utils'
import { googlePlaceReivewLink } from '../utils/google'
import { ILS } from '../constants/constants'

import Rating from './Rating'
import ExternalLink from './ExternalLink'
import WalkingTime from './WalkingTime'
import OpeningHours from './OpeningHours'
import Card from './Card'
import DishComfySlider from './DishComfySlider'

@inject('facade')
@observer
class RestaurantListItem extends Component {
  static propTypes = {
    facade: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    openingHours: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    priceness: PropTypes.number,
    gmReviewsAvg: PropTypes.number,
    gmReviews: PropTypes.number,
    googlePlacesId: PropTypes.string,
    dishes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        imageUrl: PropTypes.string,
        name: PropTypes.string.isRequired,
        price: PropTypes.number,
      })
    ).isRequired,
  }

  static defaultProps = {
    gmReviewsAvg: 0,
    gmReviews: 0,
    priceness: 0,
    googlePlacesId: '',
  }

  // static inDebugMode = true // uncomment for debugging

  componentDidMount() {
    if (RestaurantListItem.inDebugMode) {
      reportMissingProps(RestaurantListItem, this)
    }
  }

  routeToRestaurant = () => {
    const { facade, id } = this.props
    facade.routeToRestaurant({ id })
  }

  renderSubtitle() {
    const {
      classes,
      id,
      openingHours,
      priceness,
      gmReviewsAvg,
      gmReviews,
      googlePlacesId,
      latitude,
      longitude,
    } = this.props
    return (
      <div className={classes.details}>
        <ExternalLink
          className={cx(classes.reviews, classes.detail)}
          href={googlePlaceReivewLink(googlePlacesId)}
        >
          <Typography className={classes.gmReviewNumber}>
            {gmReviewsAvg}
          </Typography>
          <Rating value={gmReviewsAvg} />
          <Typography className={classes.gmReviews}>
            ({numberWithCommas(gmReviews)})
          </Typography>
        </ExternalLink>
        <WalkingTime
          restaurant={{ latitude, longitude }}
          className={classes.detail}
        />
        <Typography className={classes.detail}>
          <OpeningHours restaurantId={id} openingHours={openingHours} />
        </Typography>
        <Typography
          className={cx(classes.priceness, classes.detail)}
          variant="caption"
        >
          {ILS.repeat(priceness)}
        </Typography>
      </div>
    )
  }

  // renderHeaderLeft() {
  //   const { classes, priceness } = this.props
  //   return (
  //     <Typography className={classes.priceness} variant="caption">
  //       {ILS.repeat(priceness)}
  //     </Typography>
  //   )
  // }

  renderDishSlider() {
    const { dishes } = this.props
    return <DishComfySlider dishes={dishes} />
  }

  render() {
    const { name } = this.props
    return (
      <Card
        title={name}
        dir="rtl"
        titleOnClick={this.routeToRestaurant}
        subtitle={this.renderSubtitle()}
        slider={this.renderDishSlider()}
        // headerLeft={this.renderHeaderLeft()}
      />
    )
  }
}

const styles = theme => ({
  details: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  detail: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child):before': {
      content: '"•"',
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  gmReviewNumber: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  gmReviews: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  priceness: {
    color: theme.palette.text.secondary,
  },
})

export default withStyles(styles)(withRouter(RestaurantListItem))
