import React from 'react'
import ContentLoader from 'react-content-loader'
import withTheme from '@material-ui/styles/withTheme'
import PropTypes from 'prop-types'

const TITLE_TOP_OFFSET = 16
const TITLE_HEIGHT = 14
const VERTICAL_PADDING = 13
const DETAILS_TOP_OFFSET = TITLE_TOP_OFFSET + TITLE_HEIGHT + VERTICAL_PADDING
const DETAILS_HEIGHT = TITLE_HEIGHT * 0.75
const IMG_OFFSET = DETAILS_TOP_OFFSET + DETAILS_HEIGHT + VERTICAL_PADDING
const BOTTOM_PADDING = 16

class RestaurantListItemLoader extends React.Component {
  static propTypes = {
    theme: PropTypes.object.isRequired,
  }

  shouldComponentUpdate() {
    return false
  }

  get imageWidth() {
    const { windowWidth } = this.props.theme
    return windowWidth
  }

  get rootHeight() {
    const { windowWidth } = this.props.theme
    return IMG_OFFSET + windowWidth + BOTTOM_PADDING
  }

  render() {
    const { containerPadding, dishImgHeight, windowWidth } = this.props.theme
    return (
      <ContentLoader
        rtl
        height={String(this.rootHeight)}
        width={windowWidth}
        speed={1.3}
        primaryColor="#f0ebeb"
        secondaryColor="#d2d2d2"
      >
        <rect
          x={String(containerPadding)}
          y={String(TITLE_TOP_OFFSET)}
          rx="4"
          ry="4"
          width={String(this.imageWidth * 0.33)}
          height={String(TITLE_HEIGHT)}
        />
        <rect
          x={String(containerPadding)}
          y={String(DETAILS_TOP_OFFSET)}
          rx="3"
          ry="3"
          width={String(this.imageWidth * 0.67)}
          height={String(DETAILS_HEIGHT)}
        />
        <rect
          y={String(IMG_OFFSET)}
          rx="5"
          ry="5"
          width={String(this.imageWidth)}
          height={String(dishImgHeight)}
        />
      </ContentLoader>
    )
  }
}

export default withTheme(RestaurantListItemLoader)
