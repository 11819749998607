import { observable, action, runInAction, transaction, observe } from 'mobx'

import { INITIAL, LOADING, LOADED, ERROR } from '../constants/constants'

const INITIAL_LIST = []

export default class RestaurantListStore {
  @observable list = INITIAL_LIST

  @observable state = INITIAL

  @observable errorMessage = ''

  @observable endOfResultsModalIsOn = false

  constructor({ requestService }) {
    this.requestService = requestService
  }

  @action
  async fetchRestaurantList({ states, waitForPromise }) {
    await waitForPromise
    if (this.state === LOADING) {
      return
    }
    transaction(() => {
      this.errorMessage = ''
      this.state = LOADING
      // this.list = []
    })
    // prioritize rendering loader over the next filtered restaurants
    setTimeout(
      () => runInAction(() => this.fetchRestaurantListSimulateAsync(states)),
      0
    )
  }

  @action
  fetchRestaurantListSimulateAsync = async states => {
    const {
      data,
      errorMessage,
    } = await this.requestService.fetchRestaurantList({ ...states })
    runInAction(() => {
      if (errorMessage) {
        this.state = ERROR
        this.errorMessage = errorMessage
        return
      }
      const { restaurantList } = data
      transaction(() => {
        this.errorMessage = ''
        this.state = LOADED
        this.list = restaurantList
      })
    })
  }

  @action.bound
  toggleEndOfResultsModal = () => {
    this.endOfResultsModalIsOn = !this.endOfResultsModalIsOn
  }

  isLast = idx => idx === this.list.length - 1

  /*
   * Logging
   */

  reportStateChange = change => {
    if (change.name !== 'state') {
      return
    }
    global.console.debug(
      `[Restaurant list store] ${change.oldValue} -> ${
        change.object[change.name]
      }`
    )
  }

  stateObserver = observe(this, this.reportStateChange)
}
