import React, { Component } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import cx from 'classnames'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import Container from './Container'

class Card extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.any,
    slider: PropTypes.any,
    headerLeft: PropTypes.any,
    titleOnClick: PropTypes.func,
  }

  static defaultProps = {
    title: '',
    subtitle: null,
    headerLeft: null,
    slider: null,
    titleOnClick: noop,
  }

  get titleIsALink() {
    return this.props.titleOnClick !== noop
  }

  renderTitle() {
    const { classes, title, titleOnClick } = this.props
    if (!title) {
      return null
    }
    return (
      <Typography
        variant="h1"
        className={cx(classes.title, { [classes.link]: this.titleIsALink })}
        onClick={titleOnClick}
      >
        {title}
      </Typography>
    )
  }

  render() {
    const {
      classes,
      subtitle,
      slider,
      headerLeft,
      titleOnClick,
      ...restOfProps
    } = this.props
    return (
      <div className={classes.root} {...restOfProps}>
        <Container className={classes.header}>
          <div className={classes.headerRight}>
            {this.renderTitle()}
            {subtitle}
          </div>
          <div className={classes.headerLeft}>{headerLeft}</div>
        </Container>
        {slider}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.containerPadding,
    paddingBottom: theme.containerPadding,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
  },
  title: {},
  link: {
    color: theme.palette.primary.main,
  },
})

export default withStyles(styles)(Card)
