/* eslint-disable react/prop-types */

import React from 'react'
import { inject, observer } from 'mobx-react'
import AsyncSelect from 'react-select/async'
import cx from 'classnames'
import withStyles from '@material-ui/styles/withStyles'
import withTheme from '@material-ui/styles/withTheme'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import SearchIcon from '@material-ui/icons/Search'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import RoomServiceIcon from '@material-ui/icons/RoomService'
import CloseIcon from '@material-ui/icons/Close'
import Highlighter from 'react-highlight-words'

import {
  NO_SEARCH_RESULTS_TEXT,
  SEARCH_PLACEHOLDER,
  RESTAURANT,
  DISH,
  DISH_TYPE,
  ENTER_KEY,
} from '../constants/constants'

import Logo from './Logo'

const OPTION_SIDE_PADDING = 16
const OPTION_IMG_SIZE = 50
const OPTION_IMG_LEFT_MARGIN = 8
const OPTION_ICON_SIZE = 24

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent({ inputRef, inputIconClassName, children, ...props }) {
  return (
    <div ref={inputRef} {...props}>
      <SearchIcon className={inputIconClassName} />
      {children}
    </div>
  )
}

function Control(props) {
  const { classes, textFieldProps, searchStore } = props.selectProps
  return (
    <div>
      <TextField
        fullWidth
        className={classes.textFieldRoot}
        InputProps={{
          classes: {
            underline: classes.underlineRoot,
          },
          inputComponent,
          inputProps: {
            className: classes.input,
            inputIconClassName: classes.inputIcon,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...textFieldProps}
      />
      <CloseIcon
        onClick={() => {
          searchStore.resetQuery()
        }}
        className={cx(classes.closeIcon, {
          [classes.closeIconActive]: props.isFocused && searchStore.searchQuery,
        })}
      />
    </div>
  )
}

function Option(props) {
  const {
    children: text,
    selectProps: { classes, inputValue },
    value: {
      type,
      logoUrl,
      name,
      dishCount,
      restaurantName,
      imageUrl,
      associatedObjectsCount,
    } = {},
  } = props
  if (type === RESTAURANT) {
    return OptionHelper(
      props,
      <div className={classes.optionContainer}>
        <div className={classes.optionMediaObject}>
          <Logo src={logoUrl} name={name} />
          <div className={classes.optionMediaObjectText}>
            <Typography variant="h2">
              <Highlighter
                highlightClassName={classes.highlightText}
                textToHighlight={text}
                searchWords={[inputValue]}
              />
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {dishCount > 1 ? `${dishCount} מנות` : 'מנה אחת'}
            </Typography>
          </div>
        </div>
        <RestaurantIcon className={classes.optionMediaObjectIcon} />
      </div>
    )
  }
  if (type === DISH) {
    return OptionHelper(
      props,
      <div className={classes.optionContainer}>
        <div className={classes.optionMediaObject}>
          <img
            src={imageUrl}
            alt={name}
            className={classes.optionMediaObjectImg}
          />
          <div className={classes.optionMediaObjectText}>
            <Typography variant="h2">
              <Highlighter
                highlightClassName={classes.highlightText}
                textToHighlight={text}
                searchWords={[inputValue]}
              />
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {restaurantName}
            </Typography>
          </div>
        </div>
        <RoomServiceIcon className={classes.optionMediaObjectIcon} />
      </div>
    )
  }
  if (type === DISH_TYPE) {
    return OptionHelper(
      props,
      <div className={classes.optionContainer}>
        <Typography variant="h2">
          <Highlighter
            highlightClassName={classes.highlightText}
            textToHighlight={text}
            searchWords={[inputValue]}
          />
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {associatedObjectsCount} מנות
        </Typography>
      </div>
    )
  }

  // default - SEARCH_PLACEHOLDER
  return OptionHelper(
    props,
    <Highlighter
      highlightClassName={classes.highlightText}
      textToHighlight={text}
      searchWords={[inputValue]}
    />
  )
}

function OptionHelper(props, inner) {
  return (
    <MenuItem buttonRef={props.innerRef} component="div" {...props.innerProps}>
      {inner}
    </MenuItem>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function Value(props) {
  return (
    <Typography
      className={props.selectProps.classes.value}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function Menu(props) {
  const { classes } = props.selectProps
  return (
    <Paper square className={classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const reactSelectComponents = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  Value,
  ValueContainer,
  IndicatorsContainer: () => null,
}

@inject('searchStore', 'facade', 'locationStore')
@observer
class SearchAutoComplete extends React.Component {
  componentDidMount() {
    this.autocomplete.focus()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.keyForTrigerringRefresh !== this.props.keyForTrigerringRefresh
    ) {
      global.console.debug(
        '[Search autocomplete] -> Forcing search after address change'
      )
      this.autocomplete.focus()
    }
  }

  get selectStyles() {
    return {
      input: base => ({
        ...base,
        color: this.props.theme.palette.text.primary,
        '& input': {
          font: 'inherit',
          maxWidth: 'calc(100vw - 110px)',
        },
      }),
    }
  }

  onSelect = selected => {
    this.props.facade.selectFromSearchSuggestions(selected)
  }

  onInputChange = (value, { action }) => {
    if (action !== 'input-change') {
      return
    }
    this.props.searchStore.setQuery(value)
  }

  onKeyDown = evt => {
    const { facade } = this.props
    if (evt.key !== ENTER_KEY) {
      return
    }
    facade.onSearchEnterClick()
  }

  render() {
    const {
      classes,
      searchStore,
      facade,
      registerRef,
      keyForTrigerringRefresh,
    } = this.props
    return (
      <AsyncSelect
        key={keyForTrigerringRefresh} // after selecting address see https://github.com/JedWatson/react-select/issues/1879
        openMenuOnFocus
        // menuIsOpen // uncomment for dev
        ref={el => {
          this.autocomplete = el
          registerRef(el)
        }}
        noOptionsMessage={() => NO_SEARCH_RESULTS_TEXT}
        styles={this.selectStyles}
        value={searchStore.searchQuery}
        inputValue={searchStore.searchQuery}
        onChange={this.onSelect}
        onFocus={this.incrementAsyncForeReloadHack}
        onInputChange={this.onInputChange}
        onKeyDown={this.onKeyDown}
        classes={classes}
        components={reactSelectComponents}
        cacheOptions
        searchStore={searchStore}
        loadingMessage={() => 'טוען...'}
        defaultOptions
        loadOptions={facade.fetchSuggestions}
        placeholder={SEARCH_PLACEHOLDER}
      />
    )
  }
}

const styles = theme => ({
  textFieldRoot: {
    paddingRight: theme.containerPadding,
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  underlineRoot: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      display: 'none',
    },
  },
  input: {
    display: 'flex',
    padding: 0,
    height: theme.searchAutocompleteHeight,
    alignItems: 'center',
  },
  inputIcon: {
    fontSize: theme.searchAutocompleteIconDimensions,
    marginLeft: theme.searchAutocompleteIconMargin,
  },
  closeIcon: {
    display: 'none', // TODO fix click bug
    pointerEvents: 'none',
    fontSize: theme.searchAutocompleteHeight / 2,
    position: 'absolute',
    top: theme.searchAutocompleteHeight / 4,
    left: theme.searchAutocompleteHeight / 4,
    color: theme.palette.text.secondary,
    opacity: 0,
    transition: '0.4s opacity ease',
  },
  closeIconActive: {
    pointerEvents: 'auto',
    opacity: 0.5,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    maxWidth: 180, // TODO calc width from sorrounding elements
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  value: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    right: theme.searchAutocompletePlaceholderOffset,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    right: 0,
    left: -10,
    top: theme.searchAutocompleteHeight * 2 - 6,
    width: '100vw',
    '& > div': {
      maxHeight: `calc(100vh - ${theme.searchAutocompleteHeight * 2}px)`,
    },
  },
  option: {
    textDecoration: 'none',
    fontSize: 16,
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  highlightText: {
    fontWeight: 'bold',
  },
  optionMediaObject: {
    display: 'flex',
    alignItems: 'center',
  },
  optionMediaObjectImg: {
    marginLeft: OPTION_IMG_LEFT_MARGIN,
    width: OPTION_IMG_SIZE,
    height: OPTION_IMG_SIZE,
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
  },
  optionMediaObjectText: {
    textAlign: 'right',
    '& > h6': {
      maxWidth: `calc(100vw - ${OPTION_SIDE_PADDING * 2 +
        OPTION_IMG_SIZE +
        OPTION_IMG_LEFT_MARGIN +
        OPTION_ICON_SIZE +
        theme.spacing(2)}px)`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  optionMediaObjectIcon: {
    opacity: '0.5',
    color: theme.palette.text.secondary,
    width: OPTION_ICON_SIZE,
  },
  divider: {
    height: theme.spacing(2),
  },
})

export default withStyles(styles)(withTheme(SearchAutoComplete))
