import { computed } from 'mobx'

class S3Helper {
  DEVELOPMENT_BASE_URL =
    'https://plate-development.s3.eu-central-1.amazonaws.com/'

  STAGING_BASE_URL = 'https://plate-staging.s3.eu-central-1.amazonaws.com/'

  PRODUCTION_BASE_URL = 'https://veganprince.s3.eu-central-1.amazonaws.com/'

  constructor({ utils }) {
    this.utils = utils
  }

  getAllRestaurantsUrl = () => this.getUrl('all-restaurants.json')

  getAllCategoriesUrl = () => this.getUrl('all-categories.json')

  getUrl = path => {
    const url = this.buildUrl(path)
    this.reportGet(url)
    return url
  }

  buildUrl = path => this.baseUrl + path

  @computed
  get baseUrl() {
    if (this.utils.env.isDev()) {
      return this.DEVELOPMENT_BASE_URL
    }
    if (this.utils.env.isStaging()) {
      return this.STAGING_BASE_URL
    }
    if (this.utils.env.isProd()) {
      return this.PRODUCTION_BASE_URL
    }
    return ''
  }

  /*
   * Logging
   */

  reportGet = url => global.console.debug(`[S3] Getting ${url}`)
}

export default S3Helper
