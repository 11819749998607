import { observable, action, autorun } from 'mobx'

import filtersData from '../data/filtersData.json'
import {
  OPEN_NOW,
  GLUTEN_FREE,
  SPICY,
  IS_VEGAN,
  NOT_SPICY,
  ONLY_VEGAN,
  KOSHER,
  OPEN_ON_SATURDAY,
  VEGAN_FRIENDLY,
} from '../constants/constants'

export default class FiltersStore {
  @observable filters = this.initialFilters

  @action
  toggleSimpleFilter(filterNameKey) {
    this.filters[filterNameKey] = !this.filters[filterNameKey]
    // spicy and non spicy cancel each other
    if (filterNameKey === SPICY && this.isOn(SPICY)) {
      this.filters[NOT_SPICY] = false
    } else if (filterNameKey === NOT_SPICY && this.isOn(NOT_SPICY)) {
      this.filters[SPICY] = false
    }

    // openOnSaturday and kosher cancel each other
    if (filterNameKey === KOSHER && this.isOn(KOSHER)) {
      this.filters[OPEN_ON_SATURDAY] = false
    } else if (
      filterNameKey === OPEN_ON_SATURDAY &&
      this.isOn(OPEN_ON_SATURDAY)
    ) {
      this.filters[KOSHER] = false
    }
  }

  @action
  resetFilters() {
    this.filters = this.initialFilters
  }

  @action.bound
  isOn(filterNameKey) {
    return this.filters[filterNameKey]
  }

  @action
  hydrateState(filters) {
    this.filters = { ...this.filters, ...filters }
  }

  get initialFilters() {
    return {
      [OPEN_NOW]: false,
      [GLUTEN_FREE]: false,
      [SPICY]: false,
      [NOT_SPICY]: false,
      [ONLY_VEGAN]: false,
      [IS_VEGAN]: false,
      [OPEN_ON_SATURDAY]: false,
      [VEGAN_FRIENDLY]: false,
    }
  }

  get filtersToRender() {
    return filtersData
  }

  get activeFilters() {
    return Object.entries(this.filters).reduce(
      (acc, [key, isActive]) => (isActive ? acc.concat(key) : acc),
      []
    )
  }

  /*
   * Logging
   */

  reportChange = () => global.console.debug('[Filters] -> ', this.activeFilters)

  autoRunChange = autorun(() => this.reportChange())
}
