export const INITIAL = 'INITIAL'
export const LOADING = 'LOADING'
export const LOADED = 'LOADED'
export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'
export const API_BASE_URL = '/api/v1'

export const DISH = 'dish'
export const RESTAURANT = 'restaurant'
export const DISH_ATTRIBUTE = 'dishAttribute'
export const DISH_TYPE = 'dishType'

export const MENU_CATEGORY_NAME = 'menuCategoryName'

export const OPEN_NOW = 'openNow'
export const GLUTEN_FREE = 'glutenFree'
export const SPICY = 'spicy'
export const VERY_SPICY = 'verySpicy'
export const NOT_SPICY = 'notSpicy'
export const ONLY_VEGAN = 'onlyVegan'
export const IS_VEGAN = 'isVegan'
export const VEGAN_OPTION = 'veganOption'
export const VEGAN_FRIENDLY = 'veganFriendly'
export const KOSHER = 'kosher'
export const OPEN_ON_SATURDAY = 'openOnSaturday'
export const HAS_DELIVERY = 'hasDelivery'

export const RESTAURANT_ATTRIBUTES = [
  ONLY_VEGAN,
  VEGAN_FRIENDLY,
  KOSHER,
  HAS_DELIVERY,
  OPEN_ON_SATURDAY,
]
export const DISH_ATTRIBUTES = [
  IS_VEGAN,
  VEGAN_OPTION,
  GLUTEN_FREE,
  SPICY,
  VERY_SPICY,
  NOT_SPICY
]

export const CURRENT_LOCATION_LABEL = 'מיקום נוכחי'

export const ILS = '₪'
export const NO_SEARCH_RESULTS_TEXT = 'אין תוצאות, נסה/י חיפוש אחר'
export const SEARCH_PLACEHOLDER = 'חיפוש מנות, מסעדות וסוגי מנות'

export const ENTER_KEY = 'Enter'
