import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'
import cx from 'classnames'

@inject('facade')
@observer
class WalkingTime extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    restaurant: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  get walkingTimeNumber() {
    const { facade, restaurant } = this.props
    return facade
      .calcWalkingDistanceToRestaurant({
        restaurant,
        latitude: facade.latitude,
        longitude: facade.longitude,
      })
      .toFixed()
  }

  render() {
    const {
      classes,
      className,
      facade,
      restaurant,
      ...restOfProps
    } = this.props
    if (
      !facade.locationIsPresent ||
      !restaurant.latitude ||
      !restaurant.longitude
    ) {
      return null
    }
    return (
      <span {...restOfProps} className={cx(classes.root, className)}>
        <DirectionsWalkIcon className={classes.icon} /> {this.walkingTimeNumber}{' '}
        דק&apos;
      </span>
    )
  }
}

const style = () => ({
  root: { display: 'flex', justifyContent: 'center' },
  icon: { fontSize: 15, transform: 'rotateY(180deg)' },
})

export default withStyles(style)(WalkingTime)
