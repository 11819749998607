import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

class Logo extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }

  render() {
    const { src, name, classes, ...restOfProps } = this.props
    return (
      <img
        src={src}
        alt={`לוגו של ${name}`}
        className={classes.logo}
        {...restOfProps}
      />
    )
  }
}

const styles = theme => ({
  logo: {
    marginLeft: theme.spacing(1),
    width: theme.logoDimension,
    height: theme.logoDimension,
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
  },
})

export default withStyles(styles)(Logo)
