const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60

export const hhMmToSeconds = hhMm => {
  const [hours, minutes] = hhMm.split(':') // split it at the colons
  return (
    Number(hours) * SECONDS_IN_MINUTE * MINUTES_IN_HOUR +
    Number(minutes) * SECONDS_IN_MINUTE
  )
}

export const isRestaurantOpenNow = ({ restaurant, date }) => {
  const todaysOpeningHour = restaurant.openingHours[date.getDay()]
  if (!todaysOpeningHour) {
    global.console.error(
      '[openingHours util]: restaurant',
      restaurant,
      'has no opening hour for today',
      date.getDay()
    )
    return false
  }
  const { closes, opens } = todaysOpeningHour
  const nowInSeconds = hhMmToSeconds(`${date.getHours()}:${date.getMinutes()}`)
  const openingInSeconds = hhMmToSeconds(opens)
  const closingInSeconds = hhMmToSeconds(closes)
  return openingInSeconds <= nowInSeconds && nowInSeconds <= closingInSeconds
}
