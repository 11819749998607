import React from 'react' // eslint-disable-line no-unused-vars
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'gsap/CSSPlugin'
import './index.css'
import './utils/playground'

// uncomment for debugging
// require('mobx-logger').enableLogging()

// uncomment for perf debugging
// require('@welldone-software/why-did-you-render')(React) // eslint-disable-line import/newline-after-import
// React.Component.whyDidYouRender = true
