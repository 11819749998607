import EnvUtil from './Env.util'
import DomUtil from './Dom.util'

const createUtils = () => {
  const env = new EnvUtil()
  const dom = new DomUtil()

  return {
    env,
    dom,
  }
}
export default createUtils
