import getDistance from 'geolib/es/getDistance'

class DistanceHelper {
  MINUTES_PER_METER = 14 / 1000

  calcWalkingDistanceToRestaurant = ({ restaurant, latitude, longitude }) => {
    const distanceInMeters = getDistance(
      {
        latitude,
        longitude,
      },
      { latitude: restaurant.latitude, longitude: restaurant.longitude }
    )
    return distanceInMeters * this.MINUTES_PER_METER
  }
}

export default DistanceHelper
