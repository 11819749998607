import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'

import { ILS } from '../constants/constants'

@inject('facade')
@observer
class DishComfySlide extends Component {
  static propTypes = {
    facade: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    restaurantId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    price: PropTypes.number,
  }

  static defaultProps = {
    price: null,
  }

  onClick = () => {
    const { restaurantId, id, facade } = this.props
    facade.routeToDish({
      restaurantId,
      id,
    })
  }

  onClickWithoutPictures = () => {
    const { restaurantId, id, facade } = this.props
    facade.routeToDishInRestaurant({
      restaurantId,
      dishId: id,
    })
  }

  renderWithoutPicture() {
    const { classes, name, price, description } = this.props
    return (
      <div
        className={cx(classes.root, classes.rootWithoutPictures)}
        onClick={this.onClickWithoutPictures}
      >
        <Typography variant="h3">{name}</Typography>
        <Typography className={classes.description}>{description}</Typography>
        <Typography className={classes.priceWithoutPictures}>
          {`${price} ${ILS}`}
        </Typography>
      </div>
    )
  }

  renderWithPicture() {
    const { classes, name, imageUrl, price } = this.props
    return (
      <div className={classes.root} onClick={this.onClick}>
        <img className={classes.image} src={imageUrl} alt={name} />
        <div className={classes.nameAndPrice}>
          <Typography>{name}</Typography>
          <Typography>{`${price} ${ILS}`}</Typography>
        </div>
      </div>
    )
  }

  renderByPictureMode = () =>
    this.props.facade.isPictureMode
      ? this.renderWithPicture()
      : this.renderWithoutPicture()

  renderPlaceholder() {
    const { facade, classes } = this.props
    return (
      <div
        className={cx(classes.root, {
          [classes.rootWithoutPictures]: !facade.isPictureMode,
        })}
      />
    )
  }

  render() {
    return (
      // <React.Fragment>{this.renderByPictureMode()}</React.Fragment>
      <VisibilitySensor partialVisibility>
        {({ isVisible }) =>
          isVisible ? this.renderByPictureMode() : this.renderPlaceholder()
        }
      </VisibilitySensor>
    )
  }
}

const OFFSET_HACK = 2

const styles = theme => ({
  root: {
    border: theme.comfySlide.border,
    background: theme.comfySlide.background,
    width: theme.comfySlide.width,
    maxWidth: theme.comfySlide.maxWidth,
    height: theme.comfySlide.height,
    maxHeight: theme.comfySlide.maxHeight,
    flexShrink: 0,
    marginLeft: theme.containerPadding,
    borderRadius: theme.comfySlide.borderRadius,
    overflow: 'hidden',
    '&:only-of-type': {
      width: `calc(100% - ${theme.containerPadding}px)`,
    },
    '&:last-of-type': {
      marginLeft: theme.containerPadding - OFFSET_HACK,
    },
  },
  rootWithoutPictures: {
    padding: theme.comfySlide.padding,
    height: theme.comfySlide.heightWithoutPictures,
  },
  description: {
    height: theme.comfySlide.descriptionHeight,
    lineHeight: `${theme.comfySlide.descriptionLineHeight}px`,
    marginBottom: theme.comfySlide.descriptionMarginBottom,
    marginTop: theme.comfySlide.descriptionMarginTop,
    color: theme.typography.p.color,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: theme.comfySlide.imgHeight,
    maxHeight: 165,
    objectFit: 'cover',
  },
  nameAndPrice: {
    display: 'flex',
    height: theme.comfySlide.nameHeight,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    justifyContent: 'space-between',
  },
  priceWithoutPictures: {
    textAlign: 'left',
    display: 'block',
    height: theme.comfySlide.priceHeight,
  },
})

export default withStyles(styles)(DishComfySlide)
