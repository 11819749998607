import { hot } from 'react-hot-loader/root'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Router } from 'react-router-dom'
import ErrorBoundary from 'react-error-boundary'

import history from './utils/history'
import Header from './components/Header'
import BottomBar from './components/BottomBar'
import ErrorFallbackComponent from './components/ErrorFallbackComponent'
import ThemeProvider from './ThemeProvider'
import Routes from './Routes'
import './App.css'

@observer
class App extends Component {
  render() {
    return (
      <ThemeProvider>
        <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
          <Router history={history}>
            <Header />
            <Routes />
            <BottomBar />
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    )
  }
}

export default hot(App)
