/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

import Stars from './Stars'

class Rating extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    muiRatingClasses: PropTypes.object,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    muiRatingClasses: {},
    readOnly: true,
  }

  render() {
    const {
      classes,
      value,
      muiRatingClasses,
      readOnly,
      ...restOfProps
    } = this.props
    return (
      <Stars
        classes={{
          root: classes.starsRoot,
          icon: classes.star,
          iconButton: classes.starButton,
          ...muiRatingClasses,
        }}
        value={value}
        readOnly
        {...restOfProps}
      />
    )
  }
}

const styles = theme => ({
  starsRoot: {
    display: 'flex',
    alignItems: 'center',
    height: theme.stars.height,
  },
  starButton: {
    padding: 0,
    margin: 0,
    transform: 'scaleX(-1)',
    '& > span': {
      height: theme.stars.height,
    },
  },
  star: {
    height: theme.stars.height,
    width: theme.stars.width,
  },
})

export default withStyles(styles)(Rating)
