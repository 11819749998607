import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'

@inject('facade')
@observer
class DishType extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    isOn: PropTypes.bool.isRequired,
    imgSrc: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    imgSrcActive: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }

  // breaks mobx-react observer, filled an issue: https://github.com/mobxjs/mobx/issues/2028
  // shouldComponentUpdate(nextProps) {
  //   return nextProps.isOn !== this.props.isOn
  // }

  toggleDishType = () => this.props.facade.toggleDishType(this.props.id)

  render() {
    const { classes, isOn, name, id, imgSrc, imgSrcActive } = this.props
    return (
      <li className={classes.item} key={id} onClick={this.toggleDishType}>
        <img
          width={28}
          height={28}
          src={isOn ? imgSrcActive : imgSrc}
          className={classes.img}
          alt={id}
        />
        <Typography className={classes.name}>{name}</Typography>
      </li>
    )
  }
}

const styles = theme => ({
  item: {
    textAlign: 'center',
  },
  img: {
    maxWidth: theme.dishType.imgWidth,
  },
  name: {
    fontSize: theme.dishType.fontSize,
    width: theme.dishType.itemWidth, // hack to set width on the item which is limited by flexbox
    lineHeight: 1,
  },
})

export default withStyles(styles)(DishType)
