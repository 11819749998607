import capitalize from 'lodash/capitalize'

const { createBrowserHistory } = require('history')

const history = createBrowserHistory()

export default history

history.listen(logChanges)

function logChanges(location, action) {
  global.console.debug(
    `[History] ${capitalize(action)} ${location.pathname}${location.search}${
      location.hash
    }`
  )
}
