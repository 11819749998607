export const googleMapsPlaceLink = ({ placeId, restName }) =>
  encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${restName}&query_place_id=${placeId}`
  )

export const googlePlaceReivewLink = placeId =>
  encodeURI(`https://search.google.com/local/reviews?placeid=${placeId}`)

export const googleNavigateLink = ({ placeId, address }) =>
  encodeURI(
    `https://www.google.com/maps/dir/?api=1&destination=${address}&destination_place_id=${placeId}`
  )

export const googleMapsIframeSource = placeId =>
  `https://www.google.com/maps/embed/v1/place?q=place_id:${placeId}&key=AIzaSyCg6qDVkthZAfljBrS02HWuV2wdQ_JCy0g`
