import React from 'react'
import ReactDOM from 'react-dom'
import { observable } from 'mobx'

import Root from '../components/Root'

const RENDER_TO_ID = 'root'

let facadeInstanceId = 1

class RenderingFacadeService {
  constructor({ facade, stores }) {
    this.facadeInstance = observable(facade)
    this.stores = observable(stores)
    if (module.hot) {
      module.hot.addStatusHandler(status => {
        if (status === 'idle') {
          facadeInstanceId += 1
          this.render()
        }
      })
    }
  }

  render = () => {
    this.reportStart()
    ReactDOM.render(
      <Root
        stores={this.stores}
        facade={this.facadeInstance}
        facadeInstaceId={facadeInstanceId}
      />,
      document.getElementById(RENDER_TO_ID)
    )
    this.reportDone()
  }

  /*
   * Logging
   */

  reportStart = () => global.console.debug('[Render] -> Start')

  reportDone = () => global.console.debug('[Render] -> Done')
}

export default RenderingFacadeService
