import React, { Component } from 'react'
import MuiThemeProvider from '@material-ui/styles/ThemeProvider'
import { createMuiTheme } from '@material-ui/core/styles'
// import blue from '@material-ui/core/colors/blue'
// import teal from '@material-ui/core/colors/teal'
import red from '@material-ui/core/colors/red'

const SPACING = 8
const spacing = n => n * SPACING

const WINDOW_WIDTH = window.innerWidth
const WINDOW_HEIGHT = window.innerHeight

export const theme = createMuiTheme({
  direction: 'rtl',
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
  },
  windowWidth: WINDOW_WIDTH,
  windowHeight: WINDOW_HEIGHT,
  palette: {
    primary: {
      main: '#006699',
    },
    accent: red,
    type: 'light',
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Alef"',
    h1: {
      fontSize: spacing(2.75),
      letterSpacing: 0,
    },
    h2: {
      fontSize: spacing(2.5),
    },
    h3: {
      fontSize: spacing(2.25),
      fontWeight: 'normal',
    },
    p: {
      color: 'rgba(0, 0, 0, 0.75)',
    },
    body1: {
      fontSize: '0.94rem',
    },
  },
  dishImgHeight: '100vw',
  dishImgWidth: '100vw',
  headerHeight: spacing(7),
  headerBoxShadow: 'none',
  headerWithSearchHeight: spacing(8),
  hrBetweenDishTypesAndFiltersHeight: 1,
  hrBetweenFiltersAndRestaurantListHeight: 1,
  bottomBarHeight: spacing(6),
  searchAutocompleteHeight: spacing(5.25),
  searchAutocompleteIconDimensions: spacing(2.75),
  searchAutocompleteIconMargin: spacing(1.5),
  searchButtonContainerWidth: spacing(6),
  logoDimension: spacing(6.5),
  dishSliderDimensions: '100vw',
  sliderPadding: spacing(1),
  restaurantList: {
    item: {},
    divider: {
      height: spacing(1),
    },
    endOfResults: {
      textHeight: spacing(7),
    },
  },
  stars: {
    height: spacing(2.5),
    width: spacing(2),
  },
  filters: {
    height: spacing(6.75),
  },
  filter: {
    height: spacing(3.75),
    fontSize: 15,
  },
  dishTypes: {
    height: spacing(9),
  },
  dishType: {
    fontSize: 15,
    imgWidth: spacing(3.5),
    itemWidth: '16.5vw',
  },
  comfySlide: {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    background: '#f5f5f5',
    width: '67vw',
    maxWidth: 275,
    maxHeight: 200,
    nameHeight: spacing(3),
    priceHeight: spacing(3),
    borderRadius: spacing(1),
    imgHeight: '40vw',
    descriptionLineHeight: spacing(2.5),
    descriptionMarginTop: spacing(1),
    descriptionMarginBottom: spacing(0.5),
    padding: '10px 12px 12px 12px',
  },
})

theme.restaurantListPageInnerHeightWithoutRestaurantList =
  theme.headerWithSearchHeight +
  theme.filters.height +
  theme.hrBetweenDishTypesAndFiltersHeight +
  theme.hrBetweenFiltersAndRestaurantListHeight +
  theme.dishTypes.height

// 314/234 magic numbers :/ calculate would be better
theme.restaurantList.item.height = 314 + theme.restaurantList.divider.height
theme.restaurantList.item.heightWithoutPictures =
  234 + theme.restaurantList.divider.height

theme.restaurantList.endOfResults.height =
  theme.restaurantList.divider.height * 2 +
  theme.restaurantList.endOfResults.textHeight

theme.restaurantList.endOfResults.modalHeight =
  theme.restaurantList.endOfResults.height

theme.restaurantList.height =
  WINDOW_HEIGHT -
  theme.restaurantListPageInnerHeightWithoutRestaurantList +
  theme.restaurantList.endOfResults.height

theme.comfySlide.descriptionHeight = theme.comfySlide.descriptionLineHeight * 3

theme.comfySlide.height = `calc(${theme.comfySlide.imgHeight} + ${
  theme.comfySlide.nameHeight
}px + 12px)` // I don't know what causes the extra offset

theme.comfySlide.heightWithoutPictures = `calc( ${
  theme.comfySlide.descriptionHeight
}px + ${theme.comfySlide.nameHeight}px + ${
  theme.comfySlide.priceHeight
}px + 22px)` // I don't know what causes the extra offset

theme.comfySlider = { height: `calc(${theme.comfySlide.height} + 2px)` } // 2 for box shadow supplied by MUI Paper
theme.comfySlider = {
  heightWithoutPictures: `calc(${
    theme.comfySlide.heightWithoutPictures
  } + 2px)`,
} // 2 for box shadow supplied by MUI Paper

theme.restaurantContainerInDishPageBottom =
  theme.bottomBarHeight + theme.spacing(2)
theme.containerPadding = theme.spacing(2)

const searchAutocompleteValueContainerMargin = 2

theme.searchAutocompletePlaceholderOffset =
  theme.searchAutocompleteIconDimensions +
  theme.searchAutocompleteIconMargin +
  searchAutocompleteValueContainerMargin

class ThemeProvider extends Component {
  render() {
    return <MuiThemeProvider theme={theme} {...this.props} />
  }
}

export default ThemeProvider

reportTheme()

/*
 * Logging
 */

function reportTheme() {
  global.console.debug('[Theme]', theme)
}
