import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import withStyles from '@material-ui/styles/withStyles'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { observable } from 'mobx'
import Helmet from 'react-helmet'

import LocationAutoComplete from '../components/LocationAutoComplete'
import SearchAutoComplete from '../components/SearchAutoComplete'

@inject('facade')
@observer
class SearchPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
  }

  @observable keyForTriggeringRefresh = 1

  onSearchButtonClick = () => {
    this.props.facade.searchButtonClick()
  }

  registerSearchRef = el => {
    this.searchAutoComplete = el
  }

  onAddressSelect = () => {
    this.keyForTriggeringRefresh += 1
    // this.searchAutoComplete.focus()
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Helmet>
          <meta name="robots" content="NOINDEX, NOFOLLOW" />
        </Helmet>
        <div className={classes.topContainer}>
          <div className={classes.autocompletesContainer}>
            <SearchAutoComplete
              keyForTrigerringRefresh={this.keyForTriggeringRefresh}
              registerRef={this.registerSearchRef}
            />
            <LocationAutoComplete onSelect={this.onAddressSelect} />
          </div>
          <div className={classes.searchButtonContainer}>
            <Button
              className={classes.searchButton}
              aria-label="Search"
              color="primary"
              variant="contained"
              onClick={this.onSearchButtonClick}
              size="small"
            >
              <SearchIcon className={classes.searchButtonIcon} />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    background: 'rgba(255,255,255,1)',
  },
  topContainer: {
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,.5)',
    display: 'flex',
  },
  autocompletesContainer: {
    width: `calc(100vw - ${theme.searchButtonContainerWidth}px)`,
    flex: 1,
    borderLeft: `1px solid ${theme.palette.divider}`,
    minHeight: theme.searchAutocompleteHeight * 2,
  },
  searchButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.searchButtonContainerWidth,
    height: theme.searchAutocompleteHeight * 2,
  },
  searchButton: {
    minWidth: '0',
    padding: 5,
    boxShadow: 'none',
  },
  searchButtonIcon: {
    fontSize: 22,
  },
})

export default withStyles(styles)(SearchPage)
