import React from 'react'

const SVG = ({
  style = {},
  fill = '#006699',
  width = '100%',
  height = '20',
  className = '',
  viewBox = '0 0 45 45',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M36.953 14.4a1.38 1.38 0 0 1-.028.25L31.225 36a1.443 1.443 0 0 1-.8.935l-2.637 1.208 1.893 3.084h3.67a1.456 1.456 0 0 1 1.48 1.43V45.6a1.483 1.483 0 0 1-2.962 0v-1.512h-3.03a1.493 1.493 0 0 1-1.275-.7l-2.463-4.01-4.365 2c-.022.01-.046.005-.068.014a1.538 1.538 0 0 1-.56.122h-.022a1.54 1.54 0 0 1-.37-.046l-7.154-1.84-6.12 6.545a1.5 1.5 0 0 1-1.074.472H5.34a1.508 1.508 0 0 1-1.065-.435l-1.747-1.74a1.4 1.4 0 0 1 .032-2.022 1.52 1.52 0 0 1 2.094.03l.645.644 4.04-4.32-1.81-.47a1.46 1.46 0 0 1-1.04-.976L.035 16.406c-.013-.04-.023-.08-.032-.12s0-.084-.008-.127a1.33 1.33 0 0 1-.01-.18 1.35 1.35 0 0 1 .053-.373c.007-.033 0-.066.012-.1L4.24 3.982a1.458 1.458 0 0 1 .92-.88l9.09-3a1.53 1.53 0 0 1 1.21.11 1.435 1.435 0 0 1 .718.943l1.726 7.742 3.693.936.78-2.693a1.453 1.453 0 0 1 .93-.96l8.3-2.87a1.53 1.53 0 0 1 1.143.06 1.448 1.448 0 0 1 .76.828l3.38 9.632a1.377 1.377 0 0 1 .073.45c.006.043-.007.08-.01.12zM9.093 35.775l9.876 2.257v-17l-15-3zm4.54-32.443L6.8 5.587l-2.866 7.887 11-4.288zm3.114 8.233L6.294 15.64l12.76 2.968 1.745-6.015zm5.222 9.468v17l6.554-3.393 4.446-17.61zM31.19 6.5l-6.164 2.13-2.69 9.274 11.3-4.426z"
    />
  </svg>
)

export default SVG
