import { observable, action, runInAction, transaction, observe } from 'mobx'
import groupBy from 'lodash/groupBy'

import {
  INITIAL,
  LOADING,
  LOADED,
  ERROR,
  MENU_CATEGORY_NAME,
} from '../constants/constants'
import { getDishAttributes } from '../utils/utils'

export default class RestaurantStore {
  @observable restaurant = this.getBlankRestaurant()

  @observable state = INITIAL

  @observable errorMessage = ''

  constructor({ requestService }) {
    this.requestService = requestService
  }

  @action.bound
  fetchRestaurantFromUrl = () => {
    this.fetchRestaurant({ id: this.idFromUrl })
  }

  @action.bound
  async fetchRestaurant({ id, states = {} }) {
    if (this.state === LOADING) {
      return
    }
    transaction(() => {
      this.restaurant = this.getBlankRestaurant()
      this.errorMessage = ''
      this.state = LOADING
    })

    const { data, errorMessage } = await this.requestService.fetchRestaurant({
      id,
      states,
    })
    runInAction(() => {
      if (errorMessage) {
        transaction(() => {
          this.state = ERROR
          this.errorMessage = errorMessage
        })
        return
      }
      transaction(() => {
        this.state = LOADED
        this.restaurant = data
      })
    })
  }

  @action
  reset() {
    transaction(() => {
      this.state = INITIAL
      this.restaurant = this.getBlankRestaurant()
      this.errorMessage = ''
    })
  }

  get dishesByMenuCategory() {
    return groupBy(this.restaurant.dishes, MENU_CATEGORY_NAME)
  }

  get menuCategories() {
    return this.restaurant.dishes.reduce((acc, dish) => {
      const menuCategoryName = dish[MENU_CATEGORY_NAME]
      if (!acc.includes(menuCategoryName)) {
        acc.push(menuCategoryName)
      }
      return acc
    }, [])
  }

  get idFromUrl() {
    return Number(window.location.pathname.split('/')[2])
  }

  doesActiveMatchId(id) {
    return this.restaurant.id === id
  }

  getBlankRestaurant() {
    return { dishes: [] }
  }

  reduceDishAttributesNamesClosure = dish => (acc, attribute) => {
    if (this.doesDishHasAttribute(attribute, dish)) {
      acc.push(attribute.name)
    }
    return acc
  }

  getDishAttributesNames(dish) {
    return getDishAttributes().reduce(
      this.reduceDishAttributesNamesClosure(dish),
      []
    )
  }

  doesDishHasAttribute({ categoryNameKey }, dish) {
    return !!dish[categoryNameKey]
  }

  /*
   * Logging
   */

  reportStateChange = change => {
    if (change.name !== 'state') {
      return
    }
    global.console.debug(
      `[Restaurant store] ${change.oldValue} -> ${change.object[change.name]}`
    )
  }

  stateObserver = observe(this, this.reportStateChange)
}
