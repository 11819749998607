import isUndefined from 'lodash/isUndefined'

export default class LocalStorageManager {
  set(key, value) {
    this.debug(`Setting ${key}`, value)
    return localStorage.setItem(key, value)
  }

  get(key) {
    const unparsed = localStorage.getItem(key)
    if (isUndefined(unparsed)) {
      this.reportError(`${key} is undefined`)
      return
    }
    try {
      const parsed = JSON.parse(unparsed)
      this.debug(`Got ${key}`, parsed)
      return parsed
    } catch (err) {
      this.reportError(`Parsing ${key}`, unparsed)
    }
  }

  reset = () => {
    this.debug('Reseting')
    localStorage.clear()
  }

  setLocationGranted(flag) {
    return this.set('locationGranted', flag)
  }

  getLocationGranted() {
    return this.get('locationGranted')
  }

  setIsPictureMode(flag) {
    return this.set('isPictureMode', flag)
  }

  getIsPictureMode() {
    return this.get('isPictureMode')
  }

  /*
   * MISC
   */
  debug = (...args) => global.console.debug('[Local storage]', ...args)

  reportError = (...args) =>
    global.console.error('[Local storage] Error', ...args)
}
