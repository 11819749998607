import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/styles/withStyles'
import { inject, observer } from 'mobx-react'

import DishType from './DishType'

@inject('facade', 'dishTypeStore')
@observer
class DishTypes extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    dishTypeStore: PropTypes.object.isRequired,
  }

  renderItems() {
    const { dishTypeStore } = this.props
    return dishTypeStore.dishTypes.map(dishType => (
      <DishType
        key={dishType.id}
        {...dishType}
        isOn={dishTypeStore.isOn(dishType.id)}
      />
    ))
  }

  render() {
    const { classes } = this.props
    return (
      <ul
        className={cx(classes.root, 'no-scrollbar')}
        // style={{
        //   transform: `translateY(${-facade.restaurantListVerticalScrollPosition}px)`,
        // }}
      >
        {this.renderItems()}
      </ul>
    )
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1.5),
    paddingRight: 0,
    paddingBottom: theme.spacing(1 / 2),
    paddingLeft: theme.containerPadding,
    margin: 0,
    display: 'flex',
    height: theme.dishTypes.height,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    listStyleType: 'none',
    overflowX: 'overlay',
  },
})

export default withStyles(styles)(DishTypes)
