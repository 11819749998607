import { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

class OpeningHours extends Component {
  static propTypes = {
    restaurantId: PropTypes.number.isRequired,
    openingHours: PropTypes.arrayOf(
      PropTypes.shape({
        opens: PropTypes.string.isRequired,
        closes: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }

  get today() {
    const { openingHours = {} } = this.props
    const today = openingHours.filter(hour => {
      return hour.day === this.dayIdx
    })
    if (today.length === 1) {
      return today[0]
    }
    if (today.length === 0) {
      return {}
    }
    return today || {}
  }

  get dayIdx() {
    const date = new Date()
    return date.getDay() + 1
  }

  get isOpenAllDay() {
    const { opens, closes } = this.today
    return opens === '00:00' && closes === '23:59'
  }

  reportNoOpeningHoursProperty() {
    global.console.error(
      '[Opening Hours] -> no openingHours property on restaurant ->',
      this.props.restaurantId
    )
  }

  reportNoOpeningHoursDataForToday() {
    global.console.error(
      '[Opening Hours] -> Opening hours missing for today ->',
      'todays index: ',
      this.dayIdx,
      '-> for restaurant ->',
      this.props.restaurantId
    )
  }

  render() {
    const hasMultipleOpeningHours = Array.isArray(this.today)
    const { opens, closes } = this.today

    if (hasMultipleOpeningHours) {
      // TODO Need to handle multiple opening hours
      console.log('has Multiple Opening Hours')
      return null
    }

    if (isEmpty(this.today)) {
      return `סגור היום`
    }
    if (!isEmpty(this.today)) {
      const openingTime = parseInt(opens.replace(':', ''), 10)
      const closingTime = parseInt(closes.replace(':', ''), 10)

      const currentDate = new Date()
      const currentHours = currentDate.getHours().toString()
      const currentMinutes =
        currentDate.getMinutes().toString().length < 2
          ? `0${currentDate.getMinutes().toString()}`
          : `${currentDate.getMinutes().toString()}`

      const currentTime = parseInt(currentHours + currentMinutes, 10)

      const open = openingTime < currentTime && currentTime < closingTime
      const closed = !open

      const openingTimeDiff = openingTime - currentTime
      const openingSoon = closed && openingTimeDiff > 0 && openingTimeDiff < 30
      const openingLater = closed && openingTimeDiff > 0

      const closingTimeDiff = closingTime - currentTime
      const closingSoon = closingTimeDiff > 0 && closingTimeDiff < 30
      const closedForToday = closed && closingTimeDiff < 0
      if (!opens || !closes) {
        this.reportNoOpeningHoursDataForToday()
        return null
      }
      if (this.isOpenAllDay) {
        return `פתוח כל היום`
      }
      if (openingSoon) {
        return `נפתח עוד מעט`
      }
      if (openingLater) {
        return `נפתח ב${opens}`
      }
      if (closingSoon) {
        return `נסגר עוד מעט`
      }
      if (closedForToday) {
        return `סגור`
      }
      if (open) {
        return 'פתוח'
      }
    }

    return `לא ברור`
  }
}

export default OpeningHours
