import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router'
import cx from 'classnames'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import ButtonBase from '@material-ui/core/ButtonBase'
import HomeIcon from '@material-ui/icons/Home'
import withStyles from '@material-ui/styles/withStyles'

import { isRestaurantListRoute, isSearchRoute } from '../utils/routing'

@inject('facade')
@observer
class BottomBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  get shouldHide() {
    const {
      location: { pathname },
    } = this.props
    return isSearchRoute(pathname) || isRestaurantListRoute(pathname)
  }

  render() {
    const { classes, facade } = this.props
    return (
      <div
        className={cx(classes.searchBar, {
          [classes.isHidden]: this.shouldHide,
        })}
      >
        <ButtonBase
          className={classes.link}
          onClick={() => facade.routeToSearchAndResetQuery()}
        >
          <SearchIcon className={classes.icon} />
        </ButtonBase>

        <ButtonBase
          className={classes.link}
          onClick={() => facade.routeToRestaurantListAndReset()}
        >
          <HomeIcon className={classes.icon} />
        </ButtonBase>
      </div>
    )
  }
}

const styles = theme => ({
  searchBar: {
    borderTopWidth: 1,
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100vw',
    marginRight: 'auto', // for centering it in big monitors
    marginLeft: 'auto', // for centering it in big monitors
    zIndex: 100,
    background: theme.palette.background.default,
    height: theme.bottomBarHeight,
    opacity: 1,
    transition: '0.4s ease',
  },
  isHidden: {
    opacity: 0,
    bottom: -theme.bottomBarHeight,
    pointerEvents: 'none',
  },
  link: {
    justifyContent: 'center',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  innerLink: {
    justifyContent: 'center',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  isActive: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
})

export default withStyles(styles)(withRouter(BottomBar))
