/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
export default () => {
  const SECONDS_IN_MINUTE = 60
  const MINUTES_IN_HOUR = 60

  // eslint-disable-next-line no-unused-vars
  self.orderByDistance = ({ latitude, longitude }, restaurants) => restaurants

  self.hhMmToSeconds = hhMm => {
    const hoursAndMinutes = hhMm.split(':') // split it at the colons
    const hours = hoursAndMinutes[0]
    const minutes = hoursAndMinutes[1]
    return (
      Number(hours) * SECONDS_IN_MINUTE * MINUTES_IN_HOUR +
      Number(minutes) * SECONDS_IN_MINUTE
    )
  }

  self.isRestaurantOpenNow = ({ restaurant }) => {
    // debugger

    const date = new Date()
    const todayDayId = date.getDay() + 1

    const todaysOpeningHour = restaurant.openingHours.filter(hour => {
      return hour.day === todayDayId
    })[0]

    // const todaysOpeningHour = restaurant.openingHours[date.getDay()]

    if (!todaysOpeningHour) {
      return false
    }
    if (todaysOpeningHour) {
      const { closes, opens } = todaysOpeningHour
      const nowInSeconds = self.hhMmToSeconds(
        `${date.getHours()}:${date.getMinutes()}`
      )
      const openingInSeconds = self.hhMmToSeconds(opens)
      const closingInSeconds = self.hhMmToSeconds(closes)
      return (
        openingInSeconds <= nowInSeconds && nowInSeconds <= closingInSeconds
      )
    }
  }

  self.pipe = functions => data => {
    return functions.reduce((value, func) => func(value), data)
  }

  self.sumDishesLength = restaurants =>
    restaurants.reduce((acc, restaurant) => {
      return acc + restaurant.dishes.length
    }, 0)

  self.params = { filters: {}, location: {} }

  /* ************************************* /
  * Interface
  /************************************** */

  self.getFilteredRestaurantsAndDishes = ({ params, restaurants }) => {
    self.params = self.generateParams(params)
    self.reportFilteringStart()
    const filteredResultsAndDishes = self.pipe(self.activePipedFunctions())(
      restaurants
    )
    self.reportFilteringEnd(filteredResultsAndDishes)
    return filteredResultsAndDishes
  }

  /* ************************************* /
  * Get all piped methods
  /************************************** */

  self.activePipedFunctions = () => {
    return []
      .concat(self.activePipedRestaurantsFilterFunctions())
      .concat(self.activePipedRestaurantsDishesFilterFunctions())
      .concat(self.activePipedSortingFunctions())
  }

  self.activePipedRestaurantsFilterFunctions = () => {
    const restaurantFilterFunctions = []
    if (self.shouldFilterRestaurantAttributes()) {
      restaurantFilterFunctions.push(self.matchesRestaurantAttributes)
    }
    if (self.shouldFilterIsOpen()) {
      restaurantFilterFunctions.push(self.matchesIsOpen)
    }
    return restaurantFilterFunctions
  }

  self.activePipedRestaurantsDishesFilterFunctions = () => {
    const dishesFilterFunctions = []
    if (self.shouldFilterDishTypes()) {
      dishesFilterFunctions.push(self.matchesDishType)
    }
    if (self.shouldFilterDishAttributes()) {
      dishesFilterFunctions.push(self.matchesDishAttributes)
    }
    if (self.shouldFilterSearchQuery()) {
      dishesFilterFunctions.push(self.matchesSearchQuery)
    }
    return dishesFilterFunctions
  }

  self.activePipedSortingFunctions = () => {
    const sortFunctions = []
    if (self.shouldSortByLocation()) {
      sortFunctions.push(self.sortByLocation)
    }
    return sortFunctions
  }

  /* ************************************* /
  * Filtering methods by restaurant
  /************************************** */

  self.matchesIsOpen = restaurants =>
    restaurants.filter(restaurant => self.isRestaurantOpenNow({ restaurant }))

  self.matchesRestaurantAttributes = restaurants =>
    restaurants.filter(restaurant =>
      self.restaurantAttributes().every(attribute => restaurant[attribute])
    )

  /* ************************************* /
  * Filtering methods by restaurant's dishes
  /************************************** */

  self.matchesDishType = restaurants =>
    restaurants.reduce((accRestaurants, restaurant) => {
      const dishes = restaurant.dishes.filter(self.dishTypeFilter)
      if (dishes.length) {
        accRestaurants.push(Object.assign({}, restaurant, { dishes }))
      }
      return accRestaurants
    }, [])

  self.dishTypeFilter = dish =>
    dish.dishTypeId === self.params.filters.dishTypeId

  self.matchesDishAttributes = restaurants =>
    restaurants.reduce((accRestaurants, restaurant) => {
      const dishes = restaurant.dishes.filter(self.dishAttributesFilter)
      if (dishes.length) {
        accRestaurants.push(restaurant)
        restaurant.dishes = dishes
      }
      return accRestaurants
    }, [])

  self.dishAttributesFilter = dish =>
    self.dishAttributes().every(attribute => {
      if (attribute === "isVegan") {
        if (dish['isVegan'] || dish['veganOption']) {
          return true
        }
      }
      if (attribute === "spicy") {
        if (dish['spicy'] || dish['verySpicy']) {
          return true
        }
      }
      return dish[attribute]
    })

  /* ************************************* /
  * Filtering methods by restaurant and dishes
  /************************************** */

  self.matchesSearchQuery = restaurants =>
    restaurants.reduce((accRestaurants, restaurant) => {
      if (self.restaurantMatchesSearchQuery(restaurant)) {
        return accRestaurants.concat(restaurant)
      }
      const dishes = restaurant.dishes.filter(self.dishSearchQueryFilter)
      if (dishes.length) {
        accRestaurants.push(restaurant)
      }
      return accRestaurants
    }, [])

  self.restaurantMatchesSearchQuery = restaurant =>
    restaurant.name.includes(self.params.filters.searchQuery)

  self.dishSearchQueryFilter = dish => {
    const query = self.params.filters.searchQuery
    if (dish.name && dish.description) {
      return dish.name.includes(query) || dish.description.includes(query)
    }
    if (dish.name) {
      return dish.name.includes(query)
    }
    if (dish.description) {
      return dish.description.includes(query)
    }
    return false
  }

  /* ************************************* /
  * Sorting methods by restaurant
  /************************************** */

  self.sortByLocation = restaurants => {
    const { latitude, longitude } = self.params.location
    return self.orderByDistance({ latitude, longitude }, restaurants)
  }

  /* ************************************* /
  * Checks for which filters should be used
  /************************************** */
  // todo compare performance with using @computed getters

  self.shouldFilterIsOpen = () => self.params.filters.openNow

  self.shouldFilterRestaurantAttributes = () =>
    !!self.restaurantAttributes().length

  self.shouldFilterDishTypes = () => !!self.params.filters.dishTypeId

  self.shouldFilterDishAttributes = () => !!self.dishAttributes().length

  self.shouldFilterSearchQuery = () => !!self.params.filters.searchQuery

  /* ************************************* /
  * Check which sorting should be used
  /************************************** */

  self.shouldSortByLocation = () => {
    const { latitude, longitude } = self.params.location
    return !!(latitude && longitude)
  }

  /* ************************************* /
  * helpers
  /************************************** */

  self.generateParams = params =>
    Object.assign({}, self.handleDishTypeAndSearchQueryParams(params), {
      date: new Date(),
    })

  self.handleDishTypeAndSearchQueryParams = params => {
    if (!params.filters.dishTypeId) {
      return params
    }
    return Object.assign({}, params, {
      filters: Object.assign({}, params.filters, { searchQuery: '' }),
    })
  }

  self.restaurantAttributes = () => {
    return self.RESTAURANT_ATTRIBUTES.filter(
      restaurantAttribute => self.params.filters[restaurantAttribute]
    )
  }

  self.dishAttributes = () => {
    return self.DISH_ATTRIBUTES.filter(
      dishAttribute => self.params.filters[dishAttribute]
    )
  }

  /*
   * Logging
   */

  self.reportFilteringStart = () => {
    self.debug('Getting results', self.params)
  }

  self.reportFilteringEnd = filteredResultsAndDishes => {
    const restaurantsCount = filteredResultsAndDishes.length
    const dishCount = self.sumDishesLength(filteredResultsAndDishes)
    self.debug(
      `Got ${restaurantsCount} restaurants and ${dishCount} dishes`,
      filteredResultsAndDishes
    )
  }

  self.reportError = (...args) =>
    console.error('[Filter web worker] Error', ...args)

  self.debug = (...args) => console.debug('[Filter web worker]', ...args)

  self.reportInit = dependencies => self.debug('Init', dependencies)

  self.addEventListener('message', e => {
    if (!e) return

    if (e.data.init) {
      self.reportInit(e.data.init)
      self.DISH_ATTRIBUTES = e.data.init.DISH_ATTRIBUTES
      self.RESTAURANT_ATTRIBUTES = e.data.init.RESTAURANT_ATTRIBUTES
      return
    }
    const params = e.data.params
    const restaurants = e.data.restaurants
    const result = self.getFilteredRestaurantsAndDishes({ params, restaurants })

    postMessage(result)
  })
}
