import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'

import DishComfySlide from './DishComfySlide'

@inject('facade')
@observer
class DishComfySlider extends Component {
  static propTypes = {
    facade: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    dishes: PropTypes.array.isRequired,
  }

  get hasSingleDish() {
    return this.props.dishes.length === 1
  }

  renderDish = dish => <DishComfySlide key={dish.id} {...dish} />

  render() {
    const { classes, facade } = this.props
    let { dishes } = this.props
    if (facade.isPictureMode) {
      dishes = dishes.filter(dish => {
        return dish.imageUrl !== ''
      })
    }
    return (
      <div
        className={cx(classes.root, 'no-scrollbar', {
          [classes.rootWithSingleDish]: this.hasSingleDish,
          [classes.rootWithoutPictures]: !facade.isPictureMode,
        })}
      >
        <div
          className={cx(classes.list, {
            [classes.listWithoutPcitures]: !facade.isPictureMode,
          })}
        >
          {dishes.map(this.renderDish)}
          {/* hack to get padding/margin from the end */}
          <span style={{ width: 0 }}>&nbsp;</span>
        </div>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    paddingRight: theme.containerPadding,
    flexWrap: 'nowrap',
    overflowX: 'overlay',
    height: theme.comfySlider.height,
  },
  rootWithoutPictures: {
    height: theme.comfySlider.heightWithoutPictures,
  },
  rootWithSingleDish: {
    overflowX: 'unset',
  },
  list: {
    height: theme.comfySlider.height,
    justifyContent: 'space-between',
    display: 'flex',
  },
  listWithoutPcitures: {
    height: theme.comfySlider.heightWithoutPictures,
  },
})

export default withStyles(styles)(DishComfySlider)
