import React, { Component } from 'react'
import { observable, reaction } from 'mobx'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import withStyles from '@material-ui/styles/withStyles'
import withTheme from '@material-ui/styles/withTheme'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { inject, observer } from 'mobx-react'
import List from 'react-virtualized/dist/es/List'
import WindowScroller from 'react-virtualized/dist/es/WindowScroller'

import { ERROR, INITIAL, LOADED } from '../constants/constants'

import RestaurantListItem from './RestaurantListItem'
import RestaurantListItemLoader from './RestaurantListItemLoader'

@inject('restaurantListStore', 'facade')
@observer
class RestaurantList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    restaurantListStore: PropTypes.object.isRequired,
    facade: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  }

  @observable prevListScrollTop = 0

  @observable bodyWidth = document.body.clientWidth

  @observable listKey = 0

  pictureModeReaction = reaction(
    () => this.props.facade.isPictureMode,
    () => {
      this.listKey += 1
    }
  )

  componentWillUnmount() {
    const { facade } = this.props
    this.prevListScrollTop = 0
    facade.setRestaurantListVerticalScrollDirectionNone()
  }

  get marginTop() {
    const { restaurantListVerticalScrollPosition } = this.props.facade
    return Math.max(-restaurantListVerticalScrollPosition, -this.scrollBuffer)
  }

  get scrollOffset() {
    const { restaurantListVerticalScrollPosition } = this.props.facade
    return Math.max(restaurantListVerticalScrollPosition - this.scrollBuffer, 0)
  }

  get scrollBuffer() {
    const { theme } = this.props
    return theme.dishTypes.height + theme.filtersHeight + 2
  }

  rowHeight = ({ index }) => {
    const { theme, facade, restaurantListStore } = this.props
    let base = facade.isPictureMode
      ? theme.restaurantList.item.height
      : theme.restaurantList.item.heightWithoutPictures
    if (restaurantListStore.isLast(index)) {
      base += theme.restaurantList.endOfResults.height
    }
    return base
  }

  onListScroll = ({ scrollTop }) => {
    const { facade } = this.props
    const itIsScrollingDown = scrollTop > this.prevListScrollTop
    if (itIsScrollingDown) {
      facade.setRestaurantListVerticalScrollDirectionDown()
    } else {
      facade.setRestaurantListVerticalScrollDirectionUp()
    }
    facade.setRestaurantListVerticalScrollPosition(scrollTop)
    this.prevListScrollTop = scrollTop
  }

  rowRenderer = ({ index, style }) => {
    const { classes, restaurantListStore } = this.props
    const restaurant = restaurantListStore.list[index]
    return (
      <div
        key={restaurant.name}
        style={style}
        className="fade-in stagger"
        id={`restaurant-${restaurant.id}`}
      >
        <div className={classes.dividerRoot}>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.item}>
          <RestaurantListItem {...restaurant} />
        </div>
        {restaurantListStore.isLast(index) && (
          <Button
            type="button"
            className={classes.endOfResults}
            onClick={restaurantListStore.toggleEndOfResultsModal}
          >
            <Divider className={classes.divider} />
            <Typography className={classes.endOfResultsText}>
              כל סוף הוא התחלה חדשה...
            </Typography>
            <Divider className={classes.divider} />
          </Button>
        )}
      </div>
    )
  }

  renderLoader() {
    const { classes } = this.props
    return (
      <div className="loader" key={0}>
        <Divider className={classes.divider} />
        <RestaurantListItemLoader />
        <Divider className={classes.divider} />
        <RestaurantListItemLoader />
        <Divider className={classes.divider} />
      </div>
    )
  }

  render() {
    const {
      restaurantListStore: { state, list },
      theme,
    } = this.props
    if (state === INITIAL) {
      return null
    }
    if (state === ERROR) {
      return <div>ארעה שגיאה</div>
    }
    if (state === LOADED && !list.length) {
      return <Typography>אין תוצאות לחיפוש המבוקש</Typography>
    }
    // if (state === LOADING) {
    //   return null
    // }
    return (
      <WindowScroller>
        {({ isScrolling, scrollTop }) => (
          <List
            autoHeight
            key={this.listKey}
            className="stagger-parent"
            height={theme.restaurantList.height}
            isScrolling={isScrolling}
            rowCount={list.length}
            rowHeight={this.rowHeight}
            rowRenderer={this.rowRenderer}
            scrollTop={scrollTop}
            width={this.bodyWidth || theme.document.body.clientWidth}
            overscanRowCount={2}
          />
        )}
      </WindowScroller>
    )
  }
}

const styles = theme => ({
  root: {
    zIndex: 10000,
    background: 'red',
  },
  dividerRoot: {
    '&:first-child hr': {
      marginTop: 0,
    },
  },
  divider: {
    marginLeft: 0,
    marginRight: 0,
    height: theme.restaurantList.divider.height,
    borderTop: '1px solid rgba(0, 0, 0, 0.04)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    background: 'rgba(0, 0, 0, 0.03)',
  },
  endOfResults: {
    height: theme.restaurantList.endOfResults.height,
    width: '100%',
    display: 'block',
    padding: 0,
  },
  endOfResultsText: {
    height: theme.restaurantList.endOfResults.textHeight,
    paddingTop: theme.containerPadding,
    paddingRight: theme.containerPadding,
    paddingBottom: theme.containerPadding,
    paddingLeft: theme.containerPadding,
    fontStyle: 'italic',
    textAlign: 'center',
    direction: 'rtl',
  },
})

export default withStyles(styles)(withRouter(withTheme(RestaurantList)))
