import { observable, action, autorun } from 'mobx'

import dishTypes from '../data/dishTypesData'

export const INITIAL_TYPE = ''

class DishTypeStore {
  @observable id = INITIAL_TYPE

  @action
  toggleDishType(id) {
    this.id = this.isOn(id) ? '' : id
  }

  @action
  resetType() {
    this.id = INITIAL_TYPE
  }

  @action
  hydrateState(id) {
    this.id = id
  }

  @action
  setDishType(id) {
    this.id = id
  }

  isOn(id) {
    return id === this.id
  }

  get isActive() {
    return !!this.id
  }

  get dishTypes() {
    return dishTypes
  }

  syncFromName(name) {
    const dishType = this.matchByName(name)
    this.id = dishType ? dishType.id : ''
  }

  matchByName(name) {
    return dishTypes.find(dishType => dishType.name === name)
  }

  getDish(id) {
    return dishTypes.find(dishType => dishType.id === id)
  }

  getDishName(id) {
    return this.getDish(id).name
  }

  get active() {
    return this.getDish(this.id) || {}
  }

  get activeName() {
    return this.active.name
  }

  /*
   * Logging
   */

  reportChange = () =>
    global.console.debug('[Dish type] -> ', this.id || 'None')

  autoRunChange = autorun(() => this.reportChange())
}

export default DishTypeStore
