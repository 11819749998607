import { observable, action, runInAction, reaction } from 'mobx'

import { getDishAttributes } from '../utils/utils'
import { INITIAL, LOADING, LOADED, ERROR } from '../constants/constants'

export default class DishStore {
  @observable dish = {}

  @observable state = INITIAL

  @observable errorMessage = ''

  constructor({ requestService }) {
    this.requestService = requestService
  }

  @action.bound
  fetchDishFromUrl = () => this.fetchDish({ id: this.idFromUrl })

  @action.bound
  async fetchDish({ id, restaurantId }) {
    this.state = LOADING
    this.dish = {}
    this.errorMessage = ''
    const { data, errorMessage } = await this.requestService.fetchDish({
      id,
      restaurantId,
    })
    runInAction(() => {
      if (errorMessage) {
        this.state = ERROR
        this.errorMessage = errorMessage
        return
      }
      this.state = LOADED
      this.dish = data
    })
  }

  @action
  reset() {
    this.state = INITIAL
    this.dish = {}
    this.errorMessage = ''
  }

  @action.bound
  reduceAttributesNames = (acc, attribute) => {
    if (this.hasAttribute(attribute)) {
      acc.push(attribute.name)
    }
    return acc
  }

  get attributesNames() {
    return getDishAttributes().reduce(this.reduceAttributesNames, [])
  }

  hasAttribute({ categoryNameKey }) {
    return !!this.dish[categoryNameKey]
  }

  get idFromUrl() {
    return Number(window.location.pathname.split('/')[2])
  }

  doesActiveMatchId(id) {
    return this.dish.id === id
  }

  /*
   * Logging
   */

  reportState = state => global.console.debug('[Dish store] -> State ->', state)

  stateReaction = reaction(() => this.state, state => this.reportState(state))
}
