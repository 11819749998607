import LocalStorageManager from './LocalStorage.manager'

const createManagers = () => {
  const localStorageManager = new LocalStorageManager()
  return {
    localStorageManager,
  }
}

export default createManagers
